<template>
  <div class="card-box">
    <slot/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({})
export default class CardItem extends Vue {

}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.card-box
  padding: 1rem
  width: 200px
  min-height: 216px
  background-color: $color-white
  border-radius: 16px
  margin: 8px 16px 8px 0
  position: relative
</style>
