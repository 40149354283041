<template>
  <article class="feed-item">
    <div class="header">
      <h3
        class="header__headline text-h5"
        :aria-label="ariaPostTitle"
      >
        {{ displayName }}
      </h3>

      <div v-if="false" class="header__subline text-secondary-blue-100">
        ???
      </div>
    </div>

    <display-time-ago class="time" :date="dateTime"/>

    <div class="msg text-body2">
      <markdown-it :text="groupPost.message"/>
    </div>

    <div v-if="groupPost.imagePath" class="image">
      <protected-image
        :src="groupPost.imagePath"
        :aria-hidden="false"
        alt="Image of this post"
      />
    </div>

    <div class="buttons">
      <like-btn
        v-model="isLikedByMe"
        :number-of-likes="numberOfLikes"
        :aria-label="'Flag ' + ariaPostTitle + ' as liked'"
        @update:model-value="onUpdatedIsLikedByMe"
      />

      <button
        class="comments-button"
        @click="onClickComments"
      >
        <img :src="commentsIcon" aria-hidden="true"/>
        <span>{{ groupPost.comments.length > 0 ? groupPost.comments.length : "Comments"}}</span>
      </button>

      <button
        v-if="isCreatedByMe"
        class="delete-button"
        @click="onClickDelete"
      >
        <img :src="deleteIcon" aria-hidden="true"/><span>Delete</span>
      </button>
    </div>

    <avatar-frame
      v-if="isAdmin"
      class="avatar"
      :image="avatarPath"
      :fallback-image="groupPost.author.role === UserRole.super_admin ? SvgHc360Symbol : SvGCompanyBuilding"
      :size="48"
      :title="groupPost.author.role === UserRole.super_admin ? displayName : groupPost.author.company.name"
    />

    <user-avatar
      v-else
      class="avatar"
      :avatar="avatarPath"
      :firstname="groupPost.author.firstName"
      :lastname="groupPost.author.lastName"
      size="mid"
    />
  </article>

  <popup-modal :model-value="isCommentsModalOpen" @update:model-value="onCloseComments">
    <group-post-comments
      :user-group-id="userGroupId"
      :group-post="groupPost"
      @post-updated="onUpdatedPost"
    />
  </popup-modal>
</template>

<script lang="ts" setup>
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import DisplayTimeAgo from "@/vue/atoms/display-time-ago.vue";
import {computed, inject, onMounted, ref} from "vue";
import {parseDate} from "@/ts/utils/date-pure-functions";
import ProtectedImage from "@/vue/atoms/protected-image.vue";
import LikeBtn from "@/vue/atoms/like-btn.vue";
import type {Api} from "@/ts/classes/api";
import {UserRole} from "@/ts/types/dto/user.dto";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import SvgHc360Symbol from "@/assets/img/HC360-symbol.svg";
import SvGCompanyBuilding from "@/assets/img/company-building-avatar.svg";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import {GroupPostResponse} from "@/ts/types/dto/group.dto";
import deleteIcon from "@/assets/img/delete-trash-blue.svg";
import commentsIcon from "@/assets/img/chat.svg";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {DefaultStore} from "@/ts/store/default/default-store";
import GroupPostComments from "@/vue/molecules/group-post-comments.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";

const props = defineProps<{
  groupPost: GroupPostResponse;
  userGroupId: string;
  index: number;
}>();

const $api = inject("$api")! as Api;
const defaultStore = getModule(DefaultStore, vueStore);
const emit = defineEmits(["postDeleted", "postUpdated"]);

const isLikedByMe = ref<boolean>(props.groupPost?.isLiked ?? false);
const numberOfLikes = ref<number>(props.groupPost?.numberOfLikes ?? 0);
const isCommentsModalOpen = ref<boolean>(false);

const dateTime = computed((): Date => {
  return parseDate(props.groupPost.createdAt);
});

const onUpdatedIsLikedByMe = async(value: boolean): Promise<void> => {
  try {
    const post = await $api.likeGroupPost(props.userGroupId, props.groupPost.id, {isLiked: value});
    isLikedByMe.value = post.isLiked;
    numberOfLikes.value = post.numberOfLikes;
  } catch (err) {
    // something went wrong: undo flagging as liked-by-me
    isLikedByMe.value = !value;
  }
};

const displayName = computed((): string => {
  let name: string;
  switch (props.groupPost?.author.role) {
    case UserRole.company_admin:
      name = "Company Admin";
      break;
    case UserRole.super_admin:
      name = "HealthCheck360";
      break;
    default:
      name = `${props.groupPost.author.firstName} ${props.groupPost.author.lastName}`;
  }
  return name;
});

const isAdmin = computed((): boolean => {
  return props.groupPost.author.role === UserRole.super_admin ||
    props.groupPost.author.role === UserRole.company_admin;
});

const avatarPath = computed((): string => {
  let path: string;
  switch (props.groupPost.author.role) {
    case UserRole.company_admin:
      path = props.groupPost.author.company.logoPath;
      break;
    case UserRole.super_admin:
      path = "";
      break;
    default:
      path = props.groupPost.author.avatarPath;
  }

  return path;
});

const ariaPostTitle = computed((): string => {
  return `Post number ${props.index + 1} posted by ${displayName.value}`;
});

const isCreatedByMe = computed(() => {
  return props.groupPost.author.id === defaultStore.profile?.id
})

const onClickDelete = async () => {
  try {
    await $api.deleteGroupPost(props.userGroupId, props.groupPost.id);
    emit("postDeleted", props.groupPost.id)
  } catch (err) {
    console.error(err)
  }
};

const onClickComments = () => {
  isCommentsModalOpen.value = true
}

const onCloseComments = () => {
  isCommentsModalOpen.value = false
}

const onUpdatedPost = (updatedPost: GroupPostResponse) => {
  emit("postUpdated", updatedPost)
}

</script>

<style lang="sass" scoped>

.feed-item
  background-color: $color-white
  border-radius: 16px
  padding: 32px 16px 16px 80px
  margin-bottom: 8px
  position: relative

.header
  margin: 0 0 16px 0

.header__headline
  margin: 0

.header__subline
  font-size: 14px

.msg
  margin: 0 0 16px 0

.image
  margin: 0 0 16px 0

.time
  position: absolute
  top: 16px
  right: 16px

.avatar
  position: absolute
  left: 16px
  top: 32px

.buttons
  display: flex
  gap: 15px

.comments-button, .delete-button
  background: transparent
  border: none
  padding: 0
  color: $color-primary-hc-blue-100
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer

  span
    margin-left: 4px

</style>


