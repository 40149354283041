import ParentSignInPage from "@/vue/pages/parent-sign-in.page.vue";
import TodoPage from "@/vue/pages/todo.page.vue";
import {pagesPath} from "@/ts/router/pages-path";
import RewardsPage from "@/vue/pages/rewards-page.page.vue";
import ChallengePage from "@/vue/pages/challenge/challenge.page.vue";
import TransactionHistoryPage from "@/vue/pages/transaction-history.page.vue";
import ChangePasswordPage from "@/vue/pages/change-password.page.vue";
import {programPages} from "@/ts/router/pages/program-pages";
import {communityPages} from "@/ts/router/pages/community-pages";
import {pathwayPages} from "@/ts/router/pages/pathway-pages";
import {biometricSchedulingPages} from "@/ts/router/pages/biometric-scheduling-pages";
import {coachingPages} from "@/ts/router/pages/coaching-pages";
import {myHealthPages} from "@/ts/router/pages/my-health-pages";
import {hraPages} from "@/ts/router/pages/health-risk-assessment-pages";
import type {RouteRecordRaw} from "vue-router";
import {biometricPhysicianFormPages} from "@/ts/router/pages/biometric-physician-form";
import FaxResults from "@/vue/pages/biometric-screening/biometric-screening-fax-results.vue";
import {profilePages} from "@/ts/router/pages/profile-pages";

export const mainPages: RouteRecordRaw = {
  path: pagesPath.main.home,
  component: ParentSignInPage,
  meta: {
    loginRequired: true,
  },
  children: [
    {
      path: "",
      redirect: pagesPath.main.todo,
    },
    {
      path: pagesPath.main.todo,
      component: TodoPage,
    },
    {
      path: pagesPath.main.rewards,
      component: RewardsPage,
    },
    {
      path: pagesPath.main.transactionsHistory,
      component: TransactionHistoryPage,
    },
    {
      path: pagesPath.main.changePassword,
      component: ChangePasswordPage,
    },
    {
      path: pagesPath.challenge.challenge,
      component: ChallengePage,
    },
    {
      path: pagesPath.biometricScreening.faxResults,
      component: FaxResults,
    },
    ...profilePages,
    programPages,
    ...communityPages,
    biometricSchedulingPages,
    pathwayPages,
    myHealthPages,
    biometricPhysicianFormPages,
    coachingPages,
    hraPages,
  ],
};
