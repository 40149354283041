<template>
  <popup-modal :model-value="modelValue" @update:modelValue="onClickCancel">
    <div class="container">
      <h1 class="text-h2">
        Add Friends
      </h1>
      <form @submit.prevent="onSubmitSearch">
        <div class="search-field">
          <label>
            <span class="search-field__label">Search users</span>
            <input v-model="searchQuery" class="search-field__input" placeholder="Search Users"/>
          </label>

          <button
            class="search-field__submit keyboard-focus-border"
            type="submit"
            :disabled="searchingInProgress"
            aria-label="Submit user search"
          >
            <div v-if="searchingInProgress" class="search-field__progress-animation"/>
            <icon-chameleon
              v-else
              color="primary50"
              hover-color="primary100"
              :icon="SvgSearch"
            />
          </button>
        </div>
      </form>

      <div v-if="selectionList.length > 0">
        <div class="text-h6">
          Selected Coworkers
        </div>
        <ul class="selection" aria-label="Your selected coworkers to start a friendship with">
          <li
            v-for="friendCandidate in selectionList"
            :key="friendCandidate.id"
            class="selection__item"
          >
            <user-avatar
              :firstname="friendCandidate.firstName"
              :lastname="friendCandidate.lastName"
              :avatar="friendCandidate.avatarPath ?? ''"
            />
            <span class="selection__label">{{ friendCandidate.firstName }} {{ friendCandidate.lastName[0] }}.</span>
            <button
              class="selection__remove-btn keyboard-focus-border"
              :aria-label="`Remove ${friendCandidate.firstName} ${friendCandidate.lastName} from selection`"
              @click="onClickRemoveFromSelection(friendCandidate.id)"
            >
              <icon-chameleon
                class="selection__remove-btn-icon"
                color="primary100"
                :icon="SvgXMark"
                aria-hidden="true"
                alt="Remove Icon"
              />
            </button>
          </li>
        </ul>
      </div>

      <aria-list-box
        v-if="options.length > 0"
        v-model:selection="selection"
        :options="options"
        aria-label="Select users to start a friendship with"
        class="options"
        option-class-name="options__option"
        option-active-class-name="options__option--active"
        orientation="horizontal"
      >
        <template #item="templateProps">
          <div class="checkbox" :class="{'checkbox--checked': selection.has(templateProps.option.id)}" aria-hidden="true">
            <icon-chameleon
              v-if="selection.has(templateProps.option.id)"
              class="checkbox__icon"
              :icon="SvgCheckmark"
              color="white"
            />
          </div>
          <user-avatar
            class="options__item-avatar"
            :firstname="templateProps.option.firstName"
            :lastname="templateProps.option.lastName"
            :avatar="templateProps.option.avatarPath ?? ''"
          />
          <div>
            {{ templateProps.option.firstName }} {{ templateProps.option.lastName }}
          </div>
        </template>
      </aria-list-box>

      <div v-if="lastCompletedSearchQuery && options.length === 0" class="no-results">
        <img :src="SvgPersonPracticingYoga"/>
        <div>
          <p class="text-h3 no-results__headline">
            We're sorry!
          </p>
          <p class="no-results__text">
            There are no results for "{{ lastCompletedSearchQuery }}" to follow your steps.
          </p>
        </div>
      </div>

      <div class="button-bar">
        <button-primary :disabled="selection.size === 0" :loading="invitationInProgress" @click="onClickAddFriends">
          <icon-chameleon
            :icon="SvgPlusIcon"
            color="white"
            alt="plus icon"
            aria-hidden="true"
          />
          Add Friends
        </button-primary>
      </div>
    </div>
  </popup-modal>
</template>

<script lang="ts" setup>
import type {Ref} from "vue";
import {computed, inject, onMounted, onUpdated, ref, watch} from "vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import type {Api} from "@/ts/classes/api";
import type {FriendDto, Friends} from "@/ts/types/dto/friend.dto";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import SvgCheckmark from "@/assets/img/checkmark.svg";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import SvgPlusIcon from "@/assets/img/plus-icon.svg";
import AriaListBox from "@/vue/molecules/aria-list-box.vue";
import SvgSearch from "@/assets/img/search.svg";
import SvgXMark from "@/assets/img/x-mark-icon.svg";
import SvgPersonPracticingYoga from "@/assets/img/challenge-card-icons/person-practicing-yoga.svg";

const $api = inject("$api")! as Api;

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits(["update:modelValue", "friendsSuccessfullyAdded"]);

const options: Ref<Friends> = ref([]);
const selection: Ref<Map<string, FriendDto>> = ref(new Map<string, FriendDto>());
const invitationInProgress: Ref<boolean> = ref(false);
const searchingInProgress: Ref<boolean> = ref(false);
const searchQuery: Ref<string> = ref("");
const lastCompletedSearchQuery: Ref<string> = ref("");

const selectionList = computed((): Friends => {
  return Array.from(selection.value.values());
});

onMounted(async () => {
    await onSubmitSearch();
});

const onClickCancel = (): void => {
  emit("update:modelValue", false);
};

const onSubmitSearch = async () => { // eslint-disable-line
  searchingInProgress.value = true;
  try {
    const result = await $api.getEligibleFriends(searchQuery.value);
    options.value = result.items;
  } catch (err) {
    options.value = [];
  }
  lastCompletedSearchQuery.value = searchQuery.value;
  searchingInProgress.value = false;
};

const onClickAddFriends = async(): Promise<void> => {
  invitationInProgress.value = true;
  try {
    await $api.inviteFriends(Array.from(selection.value.keys()).map((key) => {
      return {id: key};
    }));
    emit("update:modelValue", false);
    emit("friendsSuccessfullyAdded");
  } catch (err) {
    // eslint-disable-line
  }
  invitationInProgress.value = false;
};

const onClickRemoveFromSelection = (id: string): void => {
  selection.value.delete(id);
};

watch(() => props.modelValue, (newVal: boolean) => {
  if (newVal) {
    // reset
    options.value = [];
    selection.value = new Map<string, FriendDto>();
    invitationInProgress.value = false;
    searchingInProgress.value = false;
    searchQuery.value = "";
    lastCompletedSearchQuery.value = "";
  }
});

</script>

<style lang="sass" scoped>

.container
  width: 640px
  max-height: 70vh

.options
  display: flex
  flex-wrap: wrap
  margin-bottom: 24px
  outline: none

  &:deep(.options__option)
    width: 33.33%
    display: flex
    align-items: center
    padding: 12px
    gap: 8px

  &:focus-visible:deep(.options__option--active) .checkbox
    outline: 2px solid $color-secondary-state-blue-100
    outline-offset: 3px

  &__item-avatar
    flex-shrink: 0

.checkbox
  background-color: $color-neutral-platinum-100
  width: 20px
  height: 20px
  border-radius: 8px
  flex-shrink: 0

  &--checked
    background-color: $color-secondary-state-blue-100

  &__icon
    width: 100%
    height: 100%

.selection
  display: flex
  flex-wrap: wrap
  padding: 0
  gap: 12px

  &__item
    align-items: center
    display: flex
    flex-direction: column
    position: relative

  &__label
    @include Roboto400
    font-size: 14px
    font-weight: 700
    margin-top: 10px
    text-align: center

  &__remove-btn
    position: absolute
    width: 24px
    height: 24px
    border: 2px solid $color-primary-hc-blue-100
    padding: 3px
    border-radius: 50%
    background: transparent
    right: -10px
    top: -3px

  &__remove-btn-icon
    width: calc(100%)
    height: calc(100%)

.button-bar
  display: flex
  justify-content: end

.search-field
  background: $color-neutral-platinum-40
  width: 320px
  height: 48px
  border-radius: 8px
  display: flex
  align-items: center
  justify-content: space-between
  padding: 0 12px
  margin-bottom: 24px

  &__label
    width: 1px
    height: 1px
    overflow: hidden
    display: inline-block

  &__input
    background: none
    border: none
    height: 24px
    @include Roboto400
    font-size: 16px
    color: $color-primary-hc-blue-100
    width: 250px

    &:focus
      outline: none

    &::placeholder
      color: $color-primary-hc-blue-50

  &__submit
    background: none
    border: none
    width: 24px
    height: 24px
    padding: 0

  &__progress-animation
    @include lds-spinner(10px, '', true, $color-primary-hc-blue-100)

.no-results
  margin-bottom: 32px
  display: flex
  align-items: center

  &__headline
    margin: 0 0 16px 0

  &__text
    margin: 0


</style>
