<template>
  <div class="select">
    <tabs-navigation :items="tabs"/>
  </div>
  <router-view/>
</template>

<script lang="ts" setup>
import TabsNavigation from "@/vue/atoms/tabs-navigation.vue";
import type {Tab} from "@/ts/types/component/tabs-navigation";
import {pagesPath} from "@/ts/router/pages-path";
import {getModule} from "vuex-module-decorators";
import {DefaultStore} from "@/ts/store/default/default-store";
import {vueStore} from "@/ts/store/vue-store";
import {computed} from "vue";

const defaultStore = getModule(DefaultStore, vueStore);

const tabs = computed((): Tab[] => {
  if (defaultStore.profile?.myCareEnabled) {
    return [
      {
        link: pagesPath.myHealthPath.myhealthCheckScore,
        title: "My healthcheck score",
      },
      {
        link: pagesPath.myHealthPath.myhealthCare360,
        title: "MYCARE360",
      },
      {
        link: pagesPath.myHealthPath.myhealthPastResults,
        title: "past results",
      },
    ];
  }

  return [
    {
      link: pagesPath.myHealthPath.myhealthCheckScore,
      title: "My healthcheck score",
    },
    {
      link: pagesPath.myHealthPath.myhealthPastResults,
      title: "past results",
    },
  ];
});
</script>

<style lang="sass" scoped></style>
