<template>
  <div class="outerContainer">
    <popup-modal :model-value="true" :hide-close-button="true">
      <div class="text-h4 main-text q-mb-md" style="font-weight: bold">
        {{ "Complete your profile!" }}
      </div>
      <div class="text-h6 main-text q-mb-sm">
        {{ "You're almost there! Please enter in the following information to complete your profile." }}
      </div>
      <div class="container">
        <ssoRegistrationHolderPage :as-component="true"/>
      </div>
    </popup-modal>
  </div>
  <div>
    <navbar-page title="My To-Do's" :profile-section="true">
      <calendar-list v-model="currentDay"/>
      <todo-list v-if="false" :day="currentDay"/>
    </navbar-page>
  </div>
  <add-tracking-button/>
</template>

    <script lang="ts">
    import {Component} from "vue-property-decorator";
    import ButtonPrimary from "@/vue/atoms/button-primary.vue";
    import TodoList from "@/vue/organisms/todo-list.vue";
    import CalendarList from "@/vue/organisms/calendar-list.vue";
    import NavbarPage from "@/vue/templates/navbar-page.vue";
    import {mixins} from "vue-class-component";
    import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";
    import {today} from "@/ts/utils/date-pure-functions";
    import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
    import PopupModal from "@/vue/atoms/popup-modal.vue";
    import ssoRegistrationHolderPage from "./sso-registration-holder.page.vue";
    import {useRoute} from "vue-router";
    import {OnboardingState, OnboardingStoreMixin} from "@/ts/store/onboarding/onboarding-store-instance";
    import type {CensusData} from "@/ts/types/dto/auth.dto";
    import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";

    @Component({
      name: "TodoPageSso",
      components: {
        AddTrackingButton,
        NavbarPage,
        ButtonPrimary,
        TodoList,
        CalendarList,
        ssoRegistrationHolderPage,
        PopupModal,
      },
    })
    export default class TodoPage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color"), OnboardingStoreMixin, DefaultStoreMixin) {
      currentDay: Date = today();

      @OnboardingState
      userCensusData!: CensusData | null;

      created(): void {
        if (!this.userCensusData) {
          void this.$router.push(this.$pagesPath.auth.onboarding);
        }
  }
    }

    </script>
    <style lang="sass" scoped>
    .container
      display: flex
      flex-direction: column
      width: 800px
      overflow-y: none
      max-height: 100vh

    @media (min-width:100px) and (max-width: 1273px) and (min-height: 500px) and (max-height: 700px)
      .container
        overflow-y: auto
        overflow-x: auto

    @media (min-height: 500px) and (max-height: 700px)
      .container
        max-height: 65vh
        overflow-y: auto
        overflow-x: hidden
        width: 65vw

      .outerContainer
        display: flex
        flex-direction: column
        width: 60vw
        overflow-y: auto
        max-height: 95vh


    .outerContainer
      display: flex
      flex-direction: column
      width: 800px
      overflow-y: auto
      max-height: 100vh
    </style>
