<template>
  <div v-if="modelValue" class="modal" @click="close">
    <div class="content" @click.stop>
      <img v-if="!hideCloseButton"
        src="@/assets/img/x-mark.svg"
        alt="close"
        class="close-button"
        @click="close"
      />
      <div :class="slotClasses">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "PopupModal"
})
export default class PopupModal extends Vue {
  @Prop() modelValue!: boolean;

  @Prop() slotClass!: string | undefined;

  @Prop() hideCloseButton!: boolean;

  get slotClasses(): string[] {
    return ["slot", this.slotClass ?? ""];
  }

  @Emit('update:modelValue')
  close(): boolean {
    return false;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.slot
  position: relative
  background: $color-white
  padding: 64px 40px 40px
  background-clip: padding-box
  border-radius: 24px

  @include max-device(md)
    height: 100vh
    overflow: auto

.edit
  width: 60vw
  min-width: 300px
  max-width: 700px

.bg-blue
  background-color: $color-secondary-state-blue-100

.modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba($color-primary-hc-blue-100, 0.5)
  display: flex
  justify-content: center
  align-items: center
  z-index: 1
  overflow: hidden

.close-button
  position: absolute
  right: 24px
  top: 24px
  cursor: pointer
  display: block
  margin-left: auto
  z-index: 1

.content
  position: relative

</style>
