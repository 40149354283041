<template>
  <div :class="{card: true, orange: isYouUser}">
    <div class="info">
      <div class="item">
        <user-avatar
          :avatar="ranking.challengeParticipant.avatarPath"
          :firstname="ranking.challengeParticipant.firstName"
          :lastname="ranking.challengeParticipant.lastName"
          size="small"
          :user-status="ranking.challengeParticipant.status"
        />

        <div>
          <p class="position">
            {{ suffixPosition }}
          </p>
          <p class="name" :class="{'name--deleted-acct': isDeletedAccount}">
            {{ fullname }}
          </p>
        </div>
      </div>
    </div>
    <div class="progress" :class="{'progress--deleted-acct': isDeletedAccount}">
      {{ displayDailyAverage ? dailyAverage : progress }}
      <div v-if="displayDailyAverage">
      daily average
    </div>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import {calculateSecToMinutes, suffix} from "@/ts/utils/date-pure-functions";
import type {LeaderboardChallengeDTO, RankingPosition} from "@/ts/types/dto/leaderboard-challenge.dto";
import {TaskTypeNames} from "@/ts/types/dto/leaderboard-challenge.dto";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import {UserStatus} from "@/ts/types/dto/profile.dto";
import {TaskType} from "@/ts/types/dto/activity.dto";
import {formatTaskAmountByType} from "@/ts/utils/pure-functions";
import { ChallengeDisplayType } from "@/ts/types/dto/challenges.dto";

@Component({
  name: "ChallengeRankingCard",
  components: {UserAvatar},
})
export default class ChallengeRankingCard extends Vue {
  @Prop()
  ranking!: RankingPosition;

  @DefaultState
  public profile!: Profile;

  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  get suffixPosition(): string {
    return `${this.ranking.position}${suffix(Number(this.ranking.position))}`;
  }

  get isYouUser(): boolean {
    return this.ranking.challengeParticipant.id === this.profile.id;
  }

  get isDeletedAccount(): boolean {
    return this.ranking.challengeParticipant?.status === UserStatus.anonymized;
  }

  get fullname(): string {
    if (this.isYouUser) {
      return "You";
    }

    if (this.isDeletedAccount) {
      return "Deleted Account";
    }

    return `${this.ranking.challengeParticipant?.firstName} ${this.ranking.challengeParticipant?.lastName}`;
  }

  get progress(): string {
    return formatTaskAmountByType(this.ranking.progress, this.leaderboardChallenge.taskType);
  }
  get dailyAverage(): string {
    return formatTaskAmountByType(this.ranking.dailyAverage, this.leaderboardChallenge.taskType);
  }
  get displayDailyAverage(): boolean {
    return this.ranking.displayType == ChallengeDisplayType.daily_average
  }
}
</script>

<style lang="sass" scoped>

.card
  display: flex
  max-width: 686px
  margin: 0 auto
  padding: 1.22rem 1rem
  justify-content: space-between
  border-bottom: 1.5px solid $color-neutral-platinum-100
  align-items: center
  color: $color-secondary-state-blue-100

.orange
  background-color: $color-tertiary-orange-100

  .position,
  .name,
  .progress
    color: $color-white

.info
  display: flex
  flex-direction: column
  gap: 16px

.item
  display: flex
  gap: 16px
  color: $color-primary-hc-blue-100

.name
  @include Asap700
  margin: 0
  font-size: 14px
  line-height: 22px
  text-align: left

  &--deleted-acct
    color: $color-primary-hc-blue-50

.position
  @include Roboto400
  margin: 0
  font-size: 12px
  line-height: 16px
  text-align: left

.progress
  font-size: 16px
  line-height: 22px
  color: $color-primary-hc-blue-100
  @include Roboto400
  max-width: 652px
  margin: 0

  &--deleted-acct
    color: $color-primary-hc-blue-50

</style>
