import type {AuthRefreshResponseDTO} from "@/ts/types/dto/auth.dto";
import {loggerFactory} from "@/ts/instances/logger-factory";
import type {Logger} from "lines-logger";
import {getCurrentTimestampForRequestHeader} from "@/ts/utils/date-pure-functions";

export interface ParamsCommon {
  url: string;
  authorization?: string;
  headers?: Record<string, string>;
  handle404?: true;
  skipAuthRefresh?: true;
}

export interface PostParams extends ParamsCommon {
  body?: object;
}

export interface PostMultipartParams extends ParamsCommon {
  body: FormData;
}

export interface PutParams extends ParamsCommon {
  body?: object;
}

export interface GetParams extends ParamsCommon {
  queryParams?: Record<string, any>;
}

export type DeleteParams = ParamsCommon;

export class HttpWrapper {
  /*
   * backend returns this when token is expired
   * we could also grep respone for "token is expired" but it seems not reliable, since this comes from lib
   */
  public static readonly httpTokenExpired = 401;

  private static readonly httpNoContent = 204;

  private static readonly httpNotFound = 404;
  private static readonly httpExpectationFailed = 417;


  private readonly logger: Logger;

  constructor(
    private readonly backendUrl: string,
    private readonly onTokenRefresh: () => Promise<AuthRefreshResponseDTO>,
  ) {
    this.logger = loggerFactory.getLogger("http");
  }

  public async get<T>(data: GetParams): Promise<T> {
    const params = data.queryParams
      ? `?${new URLSearchParams(data.queryParams).toString()}`
      : "";
    return this.makeRequest<T>(
      "GET",
      params,
      null,
      data,
    );
  }

  public async delete<T>(data: DeleteParams): Promise<T> {
    return this.makeRequest<T>(
      "DELETE",
      "",
      null,
      data,
    );
  }

  public async post<T>(data: PostParams): Promise<T> {
    return this.makeRequest<T>(
      "POST",
      "",
      JSON.stringify(data.body),
      data,
    );
  }

  public async postMultiData<T>(data: PostMultipartParams): Promise<T> {
    return this.makeRequest<T>(
      "POST",
      "",
      data.body,
      data,
    );
  }

  public async put<T>(data: PutParams): Promise<T> {
    return this.makeRequest<T>(
      "PUT",
      "",
      JSON.stringify(data.body),
      data,
    );
  }

  // eslint-disable-next-line max-lines-per-function, max-statements
  private async makeRequest<T>(
    method: "DELETE" | "GET" | "POST" | "PUT",
    urlParams: string,
    body: BodyInit | null,
    data: ParamsCommon,
  ): Promise<T> {
    const formattedUrl = `${this.backendUrl}/v1${data.url}${urlParams}`;
    let result = await fetch(formattedUrl, {
      method,
      mode: "cors",
      headers: {
        ...this.getAuthHeader(data.authorization),
        ...this.getTimestampHeader(),
        platform: "web",
      },
      body,
    });

    if (result.status === HttpWrapper.httpTokenExpired && data.authorization && !data.skipAuthRefresh) {
      this.logger.log("Session is expired, trying to get a new one {}", data.authorization)();
      const refreshResponse = await this.onTokenRefresh();
      const headers = {
        ...this.getAuthHeader(refreshResponse.sessionToken),
        ...this.getTimestampHeader(),
        platform: "web",
      };
      result = await fetch(formattedUrl, {
        method,
        mode: "cors",
        headers,
        body,
      });
    }

    if (result.status === HttpWrapper.httpNoContent) {
      return null as T;
    }
    if (result.status === HttpWrapper.httpNotFound && data.handle404) {
      return null as T;
    }
    if (result.status === HttpWrapper.httpExpectationFailed) {
      return result.status as T;
    }
    const isImageContentType = result.headers.get("content-type")?.includes("image");
    const isOctetStreamContentType = result.headers.get("content-type")?.includes("octet-stream");
    const isBlobResult = isImageContentType || isOctetStreamContentType;
    const resultBody: Blob | string = isBlobResult ? await result.blob() : await result.text();
    let response: any;

    if (isBlobResult) {
      response = resultBody as T;
    } else {
      try {
        response = JSON.parse(resultBody as string) as T;
      } catch (error) {
        throw Error(resultBody as string);
      }
    }

    if (result.ok) {
      return response as T;
    }

    /*
     * TODO Debt:
     * status-code get lost in this API layer and is not usable in components.
     * Here the status is added to the error message as workaround.
     */

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (typeof response.message === "string") {
      // eslint-disable-line @typescript-eslint/no-unsafe-member-access
      throw Error(`${response.message} (${result.status})`); // eslint-disable-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
    } else {
      throw Error(`${response} (${result.status})`); // eslint-disable-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/restrict-template-expressions
    }
  }

  private getAuthHeader(authorization: string | undefined): Record<string, string> {
    const headers: Record<string, string> = {};
    if (authorization) {
      headers.Authorization = `Bearer ${authorization}`;
    }
    return headers;
  }

  private getTimestampHeader(): Record<string, string> {
    return {
      currentLocalTimestamp: getCurrentTimestampForRequestHeader(),
    };
  }
}
