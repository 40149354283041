import type {RewardValidation} from "@/ts/types/dto/reward.dto";
import type {ContentReference} from "@/ts/types/dto/content-reference.dto";
import type {Task} from "@/ts/types/dto/activity.dto";


export enum TodoItemStatus {
  active = "active",
  completed = "completed",
  reward_limit_reached = "reward_limit_reached",
  completed_for_the_week = "completed_for_the_week"
}

export interface TodoItemDto {
  id: string;
  name: string;
  description: string;
  date: string;
  endDate: string;
  status: TodoItemStatus;
  contentReference: ContentReference;
  reward?: RewardValidation;
  task?: Task;
  isGateKeeper?: boolean;
  isRecommended?: boolean;
}

export interface GetTodoRequestDTO {
  startDate: string;
  endDate: string;
}
