<template>
  <div class="comments-modal">
    <h2>Comments</h2>

    <ol class="comments">
      <li
        class="comment"
        v-for="comment in comments"
        :key="comment.id"
      >
        <div class="avatar">
          <template v-if="comment.author.role === UserRole.user">
            <user-avatar
              :avatar="comment.author.avatarPath"
              :firstname="comment.author.firstName"
              :lastname="comment.author.lastName"
              size="small"
            />
          </template>
          <template v-else>
            <avatar-frame
              :image="comment.author.avatarPath"
              :fallback-image="SvGCompanyBuilding"
              :size="40"
              :title="comment.author.company.name"
            />
          </template>
        </div>
        <div class="author">
          {{ (comment.author.role === UserRole.user)
            ? `${comment.author.firstName} ${comment.author.lastName}`
            : "Company admin"
          }}
        </div>
        <display-time-ago class="time" :date="parseDate(comment.createdAt)"/>
        <div class="message">
          <markdown-it :text="comment.message"/>
        </div>
        <div class="buttons">
          <button
            v-if="comment.author.id === defaultStore.profile?.id"
            class="delete-button"
            @click="onClickDelete(comment.id)"
          >
            <img :src="deleteIcon" aria-hidden="true"/><span>Delete</span>
          </button>
        </div>
      </li>
    </ol>

    <div class="add-comment">
      <input-textarea
        id="comment"
        v-model="newComment"
        name="comment"
        class="textarea"
        placeholder="Add a comment..."
        :height="45"
      />
      <button
        class="send-button"
        :disabled="!newComment"
        @click="onAddComment"
      >
        <img :src="sendIcon" /><span>Send</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {inject, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import {GroupPostComment, GroupPostResponse} from "@/ts/types/dto/group.dto";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {DefaultStore} from "@/ts/store/default/default-store";
import InputTextarea from "@/vue/atoms/input-textarea.vue";
import sendIcon from "@/assets/img/send-icon-blue.svg";
import DisplayTimeAgo from "@/vue/atoms/display-time-ago.vue";
import {parseDate} from "@/ts/utils/date-pure-functions";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import {UserRole} from "@/ts/types/dto/user.dto";
import SvGCompanyBuilding from "@/assets/img/company-building-avatar.svg";
import deleteIcon from "@/assets/img/delete-trash-blue.svg";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";

const props = defineProps<{
  groupPost: GroupPostResponse;
  userGroupId: string;
}>();

const $api = inject("$api")! as Api;
const defaultStore = getModule(DefaultStore, vueStore);
const emit = defineEmits(["postUpdated"]);

const comments = ref<GroupPostComment[]>(props.groupPost.comments)
const newComment = ref<string>('')

const onAddComment = async () => {
  try {
    const updatedPost = await $api.addGroupPostComment(props.userGroupId, props.groupPost.id, {message: newComment.value});
    comments.value = updatedPost.comments
    newComment.value = ''
    emit("postUpdated", updatedPost)
  } catch (err) {
    console.error(err)
  }
};

const onClickDelete = async (commentId: string) => {
  try {
    await $api.deleteGroupPostComment(props.userGroupId, props.groupPost.id, commentId);
    comments.value = comments.value.filter(c => c.id !== commentId)
    emit("postUpdated", {...props.groupPost, comments: comments.value})
  } catch (err) {
    console.error(err)
  }
};

</script>

<style lang="sass" scoped>
.comments-modal
  width: 500px

  h2
    margin-top: 0

.comments
  list-style: none
  padding: 0
  margin: 20px 0
  overflow-y: scroll
  max-height: 300px

.comment
  display: grid
  grid-template-columns: 50px 1fr 1fr
  grid-template-rows: 25px auto auto
  margin-bottom: 20px

.avatar
  grid-column: 1
  grid-row: span 3

.author
  grid-column: 2
  grid-row: 1
  font-size: 14px
  font-style: normal
  font-weight: 700
  line-height: 22px
  letter-spacing: 0.2px

.time
  grid-column: 3
  grid-row: 1
  justify-self: end

.message
  grid-column: 2 / span 3
  grid-row: 2
  margin-top: -14px
  padding-right: 20px
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 22px
  letter-spacing: 0.2px

.buttons
  grid-column: 2 / span 3
  grid-row: 2
  justify-self: end

.delete-button
  background: transparent
  border: none
  padding: 0
  color: $color-primary-hc-blue-100
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  position: relative

  > span
    position: absolute
    right: 25px
    background-color: white
    padding: 2px
    font-size: 12px
    opacity: 0
    visibility: hidden
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out

  &:hover
    > span
      opacity: 1
      visibility: visible

.add-comment
  display: flex
  gap: 10px

.textarea
  padding-right: 50px

.send-button
  position: absolute
  right: 45px
  bottom: 50px
  background: transparent
  border: none
  padding: 0
  margin: 0 4px 0 0
  color: $color-primary-hc-blue-100
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer

  > span
    position: absolute
    top: -20px
    left: 25px
    background-color: white
    padding: 2px
    font-size: 12px
    opacity: 0
    visibility: hidden
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out

  &:hover
    > span
      opacity: 1
      visibility: visible

</style>


