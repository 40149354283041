export interface BiometricScreeningStatus {
  deadlineDate?: string;
  submittedDate?: string;
  currentResultsAvailable: boolean;
  pastResultsAvailable: boolean;
}

export interface BiometricScreeningResult {
  id: number;
  name: string;
  description: string;
  value: string;
  unitOfMeasure: string;
  idealRangeValue: string;
  riskLevel: string;
  riskColor: RiskColor;
  takeActionHtml: string;
  pointsReceived: number;
  pointsPossible: number;
}

export interface BiometricScreeningAdditionalResult {
  id: number;
  name: string;
  value: string;
  description: string;
  unitOfMeasure: string;
  riskLevel: string;
  riskColor: RiskColor;
  idealRangeValue: string;
  takeActionHtml?: string;
  pointsReceived?: number;
  pointsPossible?: number;
}

export interface BiometricScreeningWrapperResult {
  date: string;
  totalScore: number;
  maxScorePossible: number;
  riskLevel: string;
  riskColor: RiskColor;
  biometricScreeningResults: BiometricScreeningResult[];
  biometricScreeningAdditionalResults: BiometricScreeningAdditionalResult[];
  screeningResultId: number;
}

export interface BiometricScreeningLocation {
  id: number;
  code: string;
  name: string;
  room?: string;
  address1: string;
  address2?: string;
  city: string;
  stateCode: string;
  stateName: string;
  zipCode?: string;
  timeZoneId: string;
}

export interface BiometricScreeningAppointment {
  timestamp: string;
  location: BiometricScreeningLocation;
}

export interface GetBiometricScreeningAvailableLocationDateRequest {
  locationId: string;
}

export interface GetBiometricScreeningAvailableLocationDateSlotsRequest {
  locationId: string;
  date: string;
}

export interface PostBiometricScheduleAppointmentRequest {
  locationId: number;
  timestamp: string;
  phoneNumber: string;
}

export interface BiometricScreeningAvailable {
  deadline?: string;
}

export interface PhysicianFormAvailable {
  available: boolean;
}

export type GetBiometricScreeningAvailableLocationDatesResponse = string[];
export type GetBiometricScreeningAvailableLocationDateSlotsResponse = string[];
export type GetBiometricScreeningAvailableLocationsResponse = BiometricScreeningLocation[];

export type PostBiometricScreeningScheduleAppointmentResponse = BiometricScreeningAppointment;
export type GetBiometricScreeningScheduleAppointmentDTO = BiometricScreeningAppointment;

export type GetBiometricScreeningAvailableDTO = BiometricScreeningAvailable;
export type GetBiometricScreeningResultCurrentDTO = BiometricScreeningWrapperResult;
export type GetBiometricScreeningStatusDTO = BiometricScreeningStatus;

export interface BiometricScreening {
  id: number;
  period: string;
}

export interface BiometricScreeningFaxDetail {
  faxErrorMessage: string;
  faxReportId: string;
  faxRequestLogId: number;
  faxSid: string;
  faxStatus: string;
  faxStatusDate: string;
  includeEmail: boolean;
  isCompleted: boolean;
  isConsentGiven: boolean;
  isDelivered: boolean;
  isError: boolean;
  physicianClinic: string;
  physicianFax: string;
  physicianName: string;
  physicianPhone: string;
  requestDate: string;
  resultName: string;
  screeningResultId: number;
  userEmail: string;
  userPhoneExtension: string;
  userPhoneNumber: string;
  userPhoneType: string;
}

export interface BiometricScreeningFaxRequest {
  includeEmail: boolean;
  isConsentGiven: boolean;
  physicianClinic: string;
  physicianFax: string;
  physicianName: string;
  physicianPhone: string;
  userEmail: string;
  userPhoneNumber: string;
}

export enum RiskColor {
  green = "green",
  light_green = "light_green",
  yellow = "yellow",
  orange = "orange",
  red = "red",
  unknown = "unknown",
}

export interface BiometricScreeningScheduler {
  sourceType: "External" | "InternalRequest" | "SSO";
  url?: string;
  enforceHra: boolean;
  SSOUrl: string;
}

export enum TestKitStatus {
  unavailable = "unavailable",
  available = "available",
  processing = "processing",
}

export interface BiometricScreeningTestKitDTO {
  status: TestKitStatus;
  url?: string;
  consentText?: string;
  consentGivenText?: string;
}
