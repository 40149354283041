<template>
  <profile-field
    name="Gender"
    :content="profile.gender"
    :error="error"
    :loading="loading"
    :show-success="showSuccess"
    :is-enabled="true"
    @save="save"
  >
    <edit-gender-select v-model="gender"/>
  </profile-field>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ProfileField from "@/vue/organisms/profile/profile-field.vue";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {PutProfileRequestDTO} from "@/ts/types/dto/profile.dto";
import {profileToPutProfileRequest} from "@/ts/utils/pure-functions";
import {GenderType} from "@/ts/types/component/input.select";
import EditGenderSelect from "@/vue/molecules/edit-field/edit-gender-select.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";


@Component({
  name: 'ProfileFieldGender',
  components: {EditGenderSelect, ProfileField}
})
export default class ProfileFieldGender extends mixins(LoadingMixin, DefaultStoreMixin) {
  @DefaultState
  profile!: Profile;

  showSuccess: boolean = false;

  // this is required to populate vue3 Proxy
  gender: GenderType = GenderType.MALE;

  created(): void {
    this.gender = this.profile.gender;
  }

  @DefaultGrowlError
  async save(): Promise<void> {
    this.showSuccess = false;
    const value: PutProfileRequestDTO = {
      ...profileToPutProfileRequest(this.profile),
      gender: this.gender,
    };
    const getProfileResponseDTO = await this.$api.updateProfile(value);
    const profileData: Profile = {
      ...getProfileResponseDTO,
      dateOfBirth: this.profile.dateOfBirth,
      location: this.profile.location,
      department: this.profile.department,
      myCareEnabled: this.profile.myCareEnabled,
    };
    this.defaultStore.setProfile(profileData);
    this.showSuccess = true;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
