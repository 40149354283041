<template>
  <!--div required to proxy parent class css-->
  <router-link :to="rewardPath">
    <div
      v-if="rewardTracker"
      class="wrapper"
    >
      <img src="@/assets/img/trophy-icon.svg"/>
      <div v-if="showPoints" class="points">
        {{ rewardTracker?.currentProgramPointsInfo.earnedPoints }} PTS
      </div>
      <div v-else-if="!showPoints" class="points">
        {{ earnedCashFormatted }}
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import type {RewardsTracker} from "@/ts/types/store/default-store-types";
import {mixins} from "vue-class-component";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {CachedApiMixin} from "@/ts/mixins/cached-api-mixin";
import {formatCash} from "@/ts/utils/pure-functions";

@Component({
  name: "RewardPoints",
  components: {LoadingSuspense},
})
export default class RewardPoints extends mixins(CachedApiMixin) {
  rewardTracker: RewardsTracker | null = null;

  get showPoints(): boolean {
    if (this.rewardTracker?.currentProgramPointsInfo.isCashRewardsAvailable && !this.rewardTracker?.currentProgramPointsInfo.isPointRewardsAvailable) {
      return false;
    } else {
      return true;
    }
  }

  get rewardPath(): string {
    return this.$pagesPath.main.rewards;
  }

  get earnedCashFormatted(): string {
    return formatCash(this.rewardTracker?.earnedCash ?? 0);
  }

  get width(): string {
    if (this.rewardTracker) {
      const percents = this.rewardTracker.currentProgramPointsInfo.earnedPoints / this.rewardTracker.currentProgramPointsInfo.maxPoints;
      return `${Math.floor(percents * 100)}%`; // eslint-disable-line @typescript-eslint/no-magic-numbers
    }
    return "0";
  }

  async created(): Promise<void> {
    try {
      this.rewardTracker = await this.cachedApi.getRewardTracker();
    } catch (err) {
      // DO NOTHING, DOM element will not be rendered
    }
  }
}
</script>

<style lang="sass" scoped>
.points
  color: $color-secondary-state-blue-100
  @include Roboto700
  font-size: 14px
  letter-spacing: 0.2px

.bar-holder
  background-color: $color-secondary-state-blue-10
  height: 3px
  border-radius: 8px

.gained-holder
  background-color: $color-secondary-state-blue-100
  height: 3px
  border-radius: 8px
  max-width: 100%

.wrapper
  display: flex
  gap: 7px
  cursor: pointer

.points-and-holder
  display: flex
  flex-direction: column
  gap: 3px
</style>
