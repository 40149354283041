<template>
  <div class="group-card" :style="style">
    <div class="content">
      <h4 class="group-name">
        <markdown-it :text="userGroup.group.name "/>
      </h4>
      <div class="members">
        <span><img src="@/assets/img/icons/members.svg" alt="members"/></span>
        <span>{{ userGroup.group.memberCount }}</span>
        <span>members</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {UserGroup} from "@/ts/types/dto/group.dto";
import {Api} from "@/ts/classes/api";
import {computed, inject, onMounted, ref} from "vue";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";

const props = defineProps<{
  userGroup: UserGroup;
}>();

const $api: Api = inject("$api")!;

const imageObjectUrl = ref("");

const fetchImage = async(): Promise<void> => {
    try {
      const imageProcessingOptions = `size:true/width:650/enlarge:true`
      const image = await $api.getProtectedImage(props.userGroup.group.imagePath, imageProcessingOptions);
      imageObjectUrl.value = image.imageObjectUrl;
    } catch (err) {
      // don't display image
    }
};

onMounted(() => {
  if (!!props.userGroup.group.imagePath) {
    fetchImage();
  }
});

const style = computed(() => {
  if (imageObjectUrl.value != "") {
    const image = `url(${imageObjectUrl.value || "@/assets/img/group-placeholder.svg"})`
    return `background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), ${image};`
  }
})

</script>

<style lang="sass" scoped>
.group-card
  position: relative
  width: 100%
  transition: transform 0.3s ease
  background-size: cover
  background-position: center
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), url("@/assets/img/group-placeholder.svg")

.content
  position: absolute
  bottom: 16px
  left: 16px
  color: white

h4
  margin-top: 0
  margin-bottom: 0
  font-size: 16px
  font-style: normal
  font-weight: 700

.members
  display: flex
  align-items: center
  color: white
  font-size: 12px
  font-style: normal
  font-weight: 400

.members > span
  margin-right: 3px
</style>


