<!--eslint-disable max-len-->
<template>
      <div class="container">
      <interest-list
        :edit-mode="true"
        :interests="poolOfOptions"
        :selected-interests="onboardingStore.interests"
        @select-interest="onSelectInterest"
      /></div>
  </template>
  
  <script lang="ts" setup>
  import {useRouter} from "vue-router";
  import {inject, onMounted, ref} from "vue";
  import type {PagesPath} from "@/ts/router/pages-path";
  import type {UserInterestDTO} from "@/ts/types/dto/profile.dto";
  import type {CachedApi} from "@/ts/classes/cached-api";
  import InterestList from "@/vue/molecules/interest-list.vue";
  import useStoreModule from "@/ts/composables/use-store-module";

  const router = useRouter();
  const $pagesPath = inject<PagesPath>("$pagesPath")!;
  const $cachedApi = inject<CachedApi>("$cachedApi")!;
  const props = defineProps<{
    asComponent: boolean;
  }>();
  const {onboardingStore} = useStoreModule();
  
  const poolOfOptions = ref<UserInterestDTO[]>([]);
  
  const submit = (): void => {
  };
  
  const onSelectInterest = (selectedInterest: UserInterestDTO): void => {
    const alreadySelectedIndex = onboardingStore.interests.findIndex((item) => item.id === selectedInterest.id);
  
    if (alreadySelectedIndex === -1) {
      onboardingStore.interests.push({
        id: selectedInterest.id,
        name: selectedInterest.name,
      });
    } else {
      onboardingStore.interests.splice(alreadySelectedIndex, 1);
    }
  };
  
  onMounted(async() => {
    if(!props.asComponent){
      void router.push($pagesPath.auth.onboarding);
    }
    poolOfOptions.value = await $cachedApi.getInterestOptions();

  });

  defineExpose({ submit: submit }); //This is here so the parent component can call it
  
  </script>
  
  <style lang="sass" scoped>
  .container
    max-width:100%
  </style>
  
  