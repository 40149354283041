<template>
  <navbar-page class="body" title="Transaction History" :back-link="$pagesPath.main.rewards">
    <div v-if="!isLoading && (!rewardHistoryItems || rewardHistoryItems.length === 0)" class="wrapper">
      <img src="@/assets/img/person-practicing-yoga.svg" alt="yoga"/>
      <h3>No rewards available</h3>
      <p>Please contact your admin</p>
    </div>
    <div v-else class="container">
      <h4>
        Program Start Date: <span>{{ program?.startDate ? formatDateShortMonthName(program.startDate) : '' }}</span>
      </h4>
      <section v-for="(monthlyItem, index) in monthlyItems" :key="index" class="monthly-section">
        <h2>{{ monthlyItem.month }}</h2>
        <ul class="history-items">
          <transaction-history-item v-for="item in monthlyItem.items" :key="item.id" :item="item"/>
        </ul>
      </section>
      <button-primary
        v-if="!isLoading && allowFetchRewards"
        class="button fetch-button"
        text="Fetch More Rewards"
        theme="dark"
        @click="fetchRewards"
      />
    </div>
    <loading-suspense :loading="isLoading" :error="error"/>
  </navbar-page>
</template>

<script lang="ts" setup>
import NavbarPage from "@/vue/templates/navbar-page.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {groupBy} from "@/ts/utils/pure-functions";
import {convertDateToServerFormat, formatDateShortMonthName, getMonthYear, parseDate, today} from "@/ts/utils/date-pure-functions";
import type {GetProgramResponseDTO} from "@/ts/types/dto/program.dto";
import type {RewardHistoryItem} from "@/ts/types/dto/reward.dto";
import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import TransactionHistoryItem from "@/vue/molecules/transaction-history-item.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";

const $api = inject("$api")! as Api;

const rewardHistoryItems = ref<RewardHistoryItem[]>([]);
const program = ref<GetProgramResponseDTO | null>(null);
const isLoading = ref<boolean>(true);
const error = ref<string>("");
const allowFetchRewards = ref(true);

onMounted(async() => {
  try {
    program.value = await $api.getProgram();
  } catch (err) {
    error.value = err as string;
  } finally {
    isLoading.value = false;
  }

  await fetchRewards();
});

const fetchRewards = async(): Promise<void> => {
  isLoading.value = true;
  const offset = rewardHistoryItems.value.length;
  try {
    const data = await $api.getRewardHistory(
      {
        limit: 20,
        offset,
      },
    );
    if (data.items) {
      rewardHistoryItems.value = [...rewardHistoryItems.value, ...data.items];
    }
    if (data.pagination.total <= rewardHistoryItems.value.length) {
      allowFetchRewards.value = false;
    }
  } catch (err) {
    error.value = err as string;
  } finally {
    isLoading.value = false;
  }
};

const monthlyItems = computed((): {month: string; items: RewardHistoryItem[]}[] => {
  const grouped = groupBy(rewardHistoryItems.value, (item) => getMonthYear(parseDate(item.date)));

  return Object.entries(grouped).map(([month, items]) => ({
    month,
    items,
  }));
});
</script>
<style lang="sass" scoped>
.body
  background-color: $color-neutral-platinum-40
  background-size: contain
  color: $color-primary-hc-blue-100
  @include Asap700
  width: 100%
  height: 100%
  min-height: 100vh

.wrapper
  text-align: center

  img
    max-width: 334px
    margin-bottom: 40px

  h3
    margin: 0
    color: $color-primary-hc-blue-100
    text-align: center
    @include Roboto700
    font-size: 28px
    letter-spacing: 0.1px
    line-height: 36px

  p
    margin: 0
    color: $color-primary-hc-blue-100
    text-align: center
    @include Roboto400
    font-size: 16px
    letter-spacing: 0.1px
    line-height: 24px

.container
  @include container

.monthly-section
  max-width: 685px
  margin: 0 auto

.fetch-button
  margin: 8px auto

h4
  margin: 0
  color: $color-primary-hc-blue-100
  text-align: center
  @include Roboto700
  font-size: 16px
  letter-spacing: 0.1px

  span
    @include Roboto400

h2
  color: $color-primary-hc-blue-100
  @include Roboto700
  font-size: 20px

.history-items
  margin: 0
  padding: 0 0 20px 0
  display: flex
  flex-direction: column
  gap: 2px
</style>
