<template>
    <div class="mfa-section">
        <div class="field-set">
        <label for="mfaToken">MFA Token Verification</label>
        <input-text
            id="mfaToken"
            v-model="mfaToken"
            placeholder="Enter MFA token" 
            :maxlength="maxTokenLength"
            required
            @keyup.enter="verifyMfaTokenOnEnter"
        />
        <div class="timer">
            Time remaining: {{ countdown }}
        </div>
        <error-text :error="error"/>
        <div></div>
        <button-primary
            class="verify-mfa-btn"
            type="button"
            text="Verify MFA"
            @click="verifyMfaToken"
        />
        </div>
    </div>
  </template>
  
<script lang="ts">
  import {Component} from "vue-property-decorator";
  import InputText from "@/vue/atoms/input-text.vue";
  import ButtonPrimary from "@/vue/atoms/button-primary.vue";
  import InputCheck from "@/vue/atoms/input-check.vue";
  import InputTextarea from "@/vue/atoms/input-textarea.vue";
  import type { MfaVerificationRequestDTO} from "@/ts/types/dto/auth.dto";
  import ErrorText from "@/vue/atoms/error-text.vue";
  import {mixins} from "vue-class-component";
  import {
    LoadingMixin,
  } from "@/ts/mixins/loading-mixin";
  import {OnboardingState, OnboardingStoreMixin} from "@/ts/store/onboarding/onboarding-store-instance";
  import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";

  
  @Component({
    name: "Motivation",
    components: {
      ErrorText,
      InputTextarea,
      InputCheck,
      InputText,
      ButtonPrimary,
    },
  })
  export default class mfaInput extends mixins(LoadingMixin, OnboardingStoreMixin, DefaultStoreMixin) { 
    mfaToken: string = "";
    maxTokenLength: number = 10;
  
    showMfaInput: boolean = false;
  
    timer: number | null = null;
  
    countdown: string | null = null;

    mounted() {
        this.startTimer();
    }
  
    // Utility method to validate the redirect URI
    isValidRedirectUri(uri: string): boolean {
      // Basic validation: check if the URI is a valid URL
      try {
        new URL(uri);
        return true;
      } catch (e) {
        return false;
      }
  
      // todo: Add more sophisticated checks as necessary, such as ensuring the URI belongs to your domain
    }
  
    async verifyMfaToken(): Promise<void> {
      const authToken = localStorage.getItem("mfaToken"); // Retrieve the authToken stored locally
      if (!authToken) {
        this.error = "No auth token available.";
        return;
      }
  
      try {
        // Construct the request object with both tokens
        const request: MfaVerificationRequestDTO = {
          AuthCode: authToken,
          MfaToken: this.mfaToken,
        };
  
        // Make the API call with the constructed request
        const verificationResult = await this.$api.verifyMfaToken(request);
        if (verificationResult.sessionToken && verificationResult.refreshToken) {
          this.defaultStore.setAuthTokens(verificationResult); // Update the auth tokens in your store

          if (this.timer !== null) {
            clearInterval(this.timer);
          }

          void this.$router.push("/"); // Navigate to the home page or another appropriate route
        } else {
          // If the verificationResult doesn't include the expected tokens, handle it as a failure
          this.error = "MFA verification failed. Please try again.";
        }
      } catch (error) {
        console.error(error);
        this.error = "Failed to verify MFA token.";
      }
    }
  
    startTimer() {
      const duration = 10 * 60; // 10 minutes
      let time = duration;
      this.timer = window.setInterval(() => { // Use window.setInterval to clarify the browser context
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
  
        this.countdown = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
        time--;
  
        if (time < 0) {
          clearInterval(this.timer!); // Type assertion to number
          this.showMfaInput = false;
          this.error = "MFA token verification timed out. Please start the login process again.";
        }
      }, 1000) as unknown as number; // Type assertion if necessary
    }
  
  
    beforeUnmount() {
      if (this.timer !== null) {
        clearInterval(this.timer);
      }
    }
  
    verifyMfaTokenOnEnter(event: KeyboardEvent): void {
      event.preventDefault(); // Prevent the default form submission
      this.verifyMfaToken();
    }
  }
</script>
<style lang="sass" scoped>

.mfa-section
    margin-right: 80px
    margin-left: 80px
    display: flex
    flex-direction: column
    align-items: center

.timer
    margin-top: 10px
    font-size: 16px

.verify-mfa-btn
    margin-bottom: 40px
    margin-top: 40px
</style>

