<template>
  <popup-modal :model-value="modelValue" @update:modelValue="onClickCancel" v-if="modelValue">
    <div class="wrapper">
      <form @submit.prevent="onSubmit">
        <h1>Delete Account</h1>

        <p>
          ATTENTION - Are you sure you want to delete your account? All tracking information tied to your account will be lost and
          you will no longer be eligible for your company’s incentive. There is no way to undo this action.
          It may take up to 24 hours for your account to be deleted.
        </p>

        <label>
          <span>Type in your date of birth to delete your account.</span>
          <edit-birthday-input v-model="dob" @update:model-value="onUpdateDob"
          />
        </label>

        <div class="error-message">
          {{ error }}
        </div>

        <strong class="warning">
          Deleting the account cannot be undone.
        </strong>

        <div class="button-row">
          <button-primary
            type="button"
            :loading="false"
            text="Cancel"
            :flexible-width="true"
            theme="white"
            :disabled="deletionInProgress"
            @click="onClickCancel"
          />

          <button-primary
            type="submit"
            :loading="deletionInProgress"
            text="Delete Account"
            :flexible-width="true"
          />
        </div>
      </form>
    </div>
  </popup-modal>
</template>

<script lang="ts" setup>
import PopupModal from "@/vue/atoms/popup-modal.vue";
import InputPassword from "@/vue/atoms/input-password.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import {inject, ref, watch} from "vue";
import type {Api} from "@/ts/classes/api";
import type {Router} from "vue-router";
import {useRouter} from "vue-router";
import type {PagesPath} from "@/ts/router/pages-path";
import {deleteAccount} from "@/ts/utils/exit-flows";
import EditBirthdayInput from "@/vue/molecules/edit-field/edit-birthday-input.vue";
import {convertDateToServerFormat} from "@/ts/utils/date-pure-functions";

const props = defineProps<{
  modelValue: boolean;
}>();


const emit = defineEmits(["update:modelValue"]);

const $api: Api = inject("$api")!;

const error = ref("");

let dob: Date | null = null;
const deletionInProgress = ref(false);

const $pagesPath = inject("$pagesPath")! as PagesPath;

const router: Router = useRouter();

const logoutAndRedirect = (): void => {
  deleteAccount();
  void router.push($pagesPath.auth.accountSuccessfullyDeleted);
};

const onClickCancel = (): void => {
  dob = null;
  emit("update:modelValue", false);
};

const onUpdateDob = (val: Date | null): void => {
  dob = val ?? null;
};

const onSubmit = async(): Promise<void> => {
  deletionInProgress.value = true;
  error.value = "";
  try {
    await $api.deleteAccount({
      dob: dob != null ? convertDateToServerFormat(dob) : '',
    });
    logoutAndRedirect();
  } catch {
    // eslint-disable-next-line require-atomic-updates
    // eslint-disable-next-line max-len
    error.value = "We are sorry, we were unable to process your request. Please check your date of birth and try again. For further assistance, you can contact our support team at support@healthcheck360.com.";
  } finally {
    deletionInProgress.value = false;
  }
};

</script>

<style lang="sass" scoped>
.wrapper
  width: 380px

.password-label
  margin-bottom: 4px
  display: block

.warning
  display: block
  margin: 40px 0
  color: $color-status-error-red

.button-row
  display: flex
  gap: 10px
  justify-content: flex-end

.error-message
  @include Roboto400
  font-size: 14px
  color: $color-status-error-red
  margin-top: 20px

</style>
