/* eslint-disable line-comment-position */
import {TaskType} from "@/ts/types/dto/activity.dto";
import type {FinishedFriend} from "@/ts/types/dto/pathway.dto";
import type {Pagination} from "@/ts/types/dto/pagination.dto";
import type {ChallengeDisplayType, ChallengeType} from "@/ts/types/dto/challenges.dto";
import type {UserStatus} from "@/ts/types/dto/profile.dto";
import type {UserRole} from "@/ts/types/dto/user.dto";
import { ChallengeStatus } from "../component/challenges";

export interface LeaderboardChallengeDTO {
  id: string;
  type: ChallengeType;
  coverPath: string;
  name: string;
  description: string;
  durationDays: number;
  startDate: string;
  endDate: string;
  taskType: TaskType;
  pointsFirstPositionReward: number;
  pointsSecondPositionReward: number;
  pointsThirdPositionReward: number;
  author: ChallengeParticipant;
  myLeaderboardPosition?: LeaderboardPosition;
  stats: ChallengeStats;
  joinedFriends?: FinishedFriend[];
  hasUserJoined: boolean;
  joinedOn?: Date;
  isCompleted: boolean;
  challengeStatus: ChallengeStatus;
  hasRewards: boolean;
}

export interface ChallengeStats {
  participants: number;
  departments: number;
  cities: number;
  valueReached: number;
}

export interface LeaderboardPosition {
  position: number;
  challengeParticipant: ChallengeParticipant;
  progress: number;
  dailyAverage: number;

}

export interface ChallengeParticipant {
  id: string;
  firstName: string;
  lastName: string;
  avatarPath: string;
  role: UserRole;
  status: UserStatus;
}

export interface MeGetChallengesRequestDTO {
  limit?: number;
  offset?: number;
  onlyActive?: boolean;
  onlyAvailable?: boolean;
  onlyCompleted?: boolean;
  onlyJoined?: boolean;
  onlyGrace?: boolean;
}
export const TaskTypeNames: Record<TaskType, string> = {
  [TaskType.biking_distance]: "Biking (mi)",
  [TaskType.biking_time]: "Biking (min)",
  [TaskType.calorie_intake]: "Calorie intake",
  [TaskType.dancing_time]: "Dancing (min)",
  [TaskType.ergometer_time]: "Ergometer (min)",
  [TaskType.fitness_time]: "Fitness (min)",
  [TaskType.gardening_time]: "Gardening (min)",
  [TaskType.general_physical_activity_time_or_steps]: "General Physical Activity (min or steps)",
  [TaskType.golf_time]: "Golf (min)",
  [TaskType.gymnastics_time]: "Gymnastics (min)",
  [TaskType.handbiking_time]: "Handbiking (min)",
  [TaskType.heart_rate]: "Heart Rate",
  [TaskType.hiking_distance]: "Hiking (mi)",
  [TaskType.hiking_time]: "Hiking (min)",
  [TaskType.housework_time]: "Housework (min)",
  [TaskType.jogging_distance]: "Jogging (mi)",
  [TaskType.jogging_time]: "Jogging (min)",
  [TaskType.meditation_time]: "Meditation (min)",
  [TaskType.general_physical_activity_time]: "General Physical Activity (min)",
  [TaskType.mood]: "Mood",
  [TaskType.nicotine_use]: "Nicotine Use",
  [TaskType.pilates_time]: "Pilates (min)",
  [TaskType.rowing_time]: "Rowing (min)",
  [TaskType.sleep_time]: "Sleep",
  [TaskType.steps]: "Steps",
  [TaskType.swimming_time]: "Swimming (min)",
  [TaskType.team_sports_time]: "Team Sports (min)",
  [TaskType.water_drinking_volume]: "Water Drinking (volume)",
  [TaskType.weight]: "Weight",
  [TaskType.weightlifting_time]: "Weightlifting (min)",
  [TaskType.wheelchair_time]: "Wheelchair (min)",
  [TaskType.yoga_time]: "Yoga (min)",
};

export interface LeaderboardChallengeParticipants {
  pagination: Pagination;
  items: ChallengeParticipant[];
}

export interface RankingPosition {
  challengeParticipant: ChallengeParticipant;
  progress: number;
  position: number;
  dailyAverage?: number;
  displayType: ChallengeDisplayType;
}

export interface LeaderboardChallengeRanking {
  pagination: Pagination;
  items: RankingPosition[];
}
