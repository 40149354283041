<template>
  <loading-suspense :loading="loading" :error="error">
    <empty-state
      v-if="!groupPostClusters.length"
      :image="SvgPersonPracticingYoga"
      title="You’re early to the party!"
      description="You’re a pioneer, and the first one of your company to sign in. Give them some time."
      description-max-width="440px"
    />

    <div class="create-post">
      <button-primary
        theme="white"
        class="btn"
        text="Create post"
        :icon="SvgPlusIcon"
        @click="onClickCreatePost()"
      />
    </div>

    <div v-for="(postCluster, clusterIndex) in groupPostClusters" :key="clusterIndex">
      <h2 class="text-h4 headline" :aria-label="postCluster.ariaHeadline">
        <display-day :date="postCluster.date"/>
      </h2>
      <group-post
        v-for="(post, feedItemIndex) in postCluster.items"
        :key="post.id"
        :group-post="post"
        :userGroupId="userGroupId"
        :index="postCluster.firstItemIndex + feedItemIndex"
        @post-deleted="onDeletedPost"
        @post-updated="onUpdatedPost"
      />
    </div>

    <div class="more-btn-container">
      <button-primary
        v-if="numTotalPosts > posts.length"
        text="Load more posts"
        :loading="loading"
        @click="fetchFeed(DEFAULT_LIMIT)"
      />
    </div>
  </loading-suspense>

  <popup-modal :model-value="isCreationModalOpen" @update:model-value="onCloseCreatePost">
    <group-post-creation :user-group-id="userGroupId" @post-created="onCreatePost" />
  </popup-modal>
</template>

<script lang="ts" setup>
import {Api} from "@/ts/classes/api";
import {computed, inject, onMounted, Ref, ref} from "vue";
import type {GroupPostCluster, GroupPostResponse} from "@/ts/types/dto/group.dto";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import EmptyState from "@/vue/atoms/empty-state.vue";
import DisplayDay from "@/vue/atoms/display-day.vue";
import {formatDateShortMonthName, getStartOfTheDay, isSameDay, parseDate} from "@/ts/utils/date-pure-functions";
import SvgPersonPracticingYoga from "@/assets/img/challenge-card-icons/person-practicing-yoga.svg";
import GroupPost from "@/vue/molecules/group-post.vue"
import SvgPlusIcon from "@/assets/img/plus-icon.svg";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import GroupPostCreation from "@/vue/molecules/group-post-creation.vue";


const $api: Api = inject("$api")! as Api

const props = defineProps<{
  userGroupId: string;
}>();

const DEFAULT_LIMIT = 10

const loading = ref(true)
const error = ref('')
const posts: Ref<GroupPostResponse[]> = ref<GroupPostResponse[]>([]);
const numTotalPosts: Ref<number> = ref(0);
const isCreationModalOpen = ref(false)

onMounted(async () => {
  loading.value = true
  await fetchFeed(DEFAULT_LIMIT)
  loading.value = false
})

const fetchFeed = async(limit: number): Promise<void> => {
  const offset = posts.value.length;

  try {
    const feedData = await $api.getGroupFeed(props.userGroupId, limit, offset);

    const existingItems = posts.value;
    const newItems = feedData.items.map((newItem) => {
      /*
       * remove doubled items with same ID
       * doubled items will occur if somebody creates a new post after loading the first bunch of items and
       * before clicking the "more" button
       */
      const alreadyExist = Boolean(existingItems.find((existingItem) => existingItem.id === newItem.id));
      if (alreadyExist) {
        return null;
      }
      return newItem;
    }).filter((item) => Boolean(item)) as GroupPostResponse[];

    posts.value = [
      ...existingItems,
      ...newItems,
    ];

    numTotalPosts.value = feedData.pagination.total;
  } catch (e) {
    console.error(e)
    error.value = e as string
  }
};

const groupPostClusters = computed((): GroupPostCluster[] => {
  const clusters: GroupPostCluster[] = [];
  posts.value.forEach((item, index) => {
    if (clusters.length > 0 && isSameDay(clusters[clusters.length - 1].date, item.createdAt)) {
      // add item to existing cluster
      clusters[clusters.length - 1].items.push(item);
    } else {
      // create new cluster
      const date = getStartOfTheDay(parseDate(item.createdAt));
      clusters.push({
        items: [item],
        date,
        ariaHeadline: `All posts from ${formatDateShortMonthName(date)}`,
        firstItemIndex: index,
      });
    }
  });
  return clusters;
});

const onClickCreatePost = () => {isCreationModalOpen.value = true}

const onCloseCreatePost = async () => {isCreationModalOpen.value = false}

const onCreatePost = async () => {
  // Reset state and refetch to get newly created data
  posts.value = []
  numTotalPosts.value = 0
  await fetchFeed(DEFAULT_LIMIT)
  isCreationModalOpen.value = false
}

const onDeletedPost = async (postId: string) => {
  posts.value = posts.value.filter(p => p.id !== postId)
  numTotalPosts.value--
  await fetchFeed(1)
}

const onUpdatedPost = (updatedPost: GroupPostResponse) => {
  const index = posts.value.findIndex(p => p.id === updatedPost.id)

  if (index !== -1) {
    posts.value[index] = updatedPost
  }
}
</script>

<style lang="sass" scoped>
.create-post, .more-btn-container
  padding-top: 24px
  display: flex
  justify-content: center
</style>
