<template>
    <q-card>
      <q-card-section class="items-center"/>
      <q-stepper
        ref="stepper"
        v-model="step"
        animated
        active-color="hc-primary"
        done-color="hc-secondary"
        >
        <q-step
          :name="1"
          title="Additional Details"
          caption="Tell us a little more about yourself"
          icon="home"
          :done="step > 1"
          :header-nav="step > 1"
        >
          <div>
            <user-additional-sso-page ref="additionalPage" :as-component="true" v-if="step ==1"/>

            <br v-if="additionDetailsErr?.length"/>
            <span v-if="additionDetailsErr?.length" class="warn-txt-sso">{{ additionDetailsErr }}</span>
          </div>
        </q-step>


        <q-step
          :name="2"
          title="Interests"
          icon="menu_book"
          :done="step > 2"
          :header-nav="step > 2"
        >
          <div><userInterestsSsoPage ref="interests" :as-component="true"/></div>
        </q-step>

        <q-step :name="3" title="Motivation" icon="psychology_alt">
          <div><motivation ref="motive" :as-component="true"/></div>
        </q-step>

        <template #navigation>
          <q-stepper-navigation class="q-mt-md">
            <div class="q-gutter-sm q-mr-md row items-center justify-end">
              <q-btn
                v-if="step > 1"
                flat
                color="hc-secondary"
                label="Back"
                class="q-ml-sm"
                @click="stepper?.previous"
              />
              <q-btn color="hc-primary" block  :label="step === 3 ? 'Finish' : 'Continue'" @click="handleStepForward"/>
            </div>
          </q-stepper-navigation>
        </template>
      </q-stepper>
    </q-card>
</template>

<script lang="ts" setup>
import {inject, onMounted, onUnmounted, ref} from "vue";
import {OnboardingStore} from "@/ts/store/onboarding/onboarding-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {useRouter} from "vue-router";
import type {PagesPath} from "@/ts/router/pages-path";
import type {CachedApi} from "@/ts/classes/cached-api";
import type {CensusData} from "@/ts/types/dto/auth.dto";
import {QStepper, QStep, QStepperNavigation, QBtn, useQuasar, QCard, QHeader, QSelect} from "quasar";
import userAdditionalSsoPage from "./user-additional-sso.page.vue";
import userInterestsSsoPage from "./user-interests-sso.page.vue";
import Motivation from "./motivation-sso.page.vue";

const phoneNumber = ref<string>("+1");
const address1 = ref<string>("");
const address2 = ref<string>("");
const city = ref<string>("");
const state = ref<string>("");
const postcode = ref<string>("");
const onboardingStore: OnboardingStore = getModule(OnboardingStore, vueStore);
const router = useRouter();
const $pagesPath = inject<PagesPath>("$pagesPath")!;
const $cachedApi = inject<CachedApi>("$cachedApi")!;
const censusData = ref<CensusData>();
const stepper = ref<QStepper>();
const additionalPage = ref<typeof userAdditionalSsoPage>();
const interests = ref<typeof userInterestsSsoPage>();
const motive = ref();
const email = ref<string>("");
const step = ref(1);
const done1 = ref(true);
const done2 = ref(false);
const done3 = ref(false);
const additionDetailsErr = ref<string>("");
let linkElement: any;
const props = defineProps<{
  asComponent: boolean;
}>();
onMounted(() => {
  linkElement = document.createElement("link");
  linkElement.rel = "stylesheet";
  linkElement.href = "/css/quasar.css";
  document.head.appendChild(linkElement);

  if (!props.asComponent) {
      void router.push($pagesPath.auth.onboarding);
  }
  if (onboardingStore.userAdditionalData) {
    address1.value = onboardingStore.userAdditionalData!.address1;
    city.value = onboardingStore.userAdditionalData!.city;
    address2.value = onboardingStore.userAdditionalData!.address2;
    phoneNumber.value = onboardingStore.userAdditionalData!.phoneNumber;
    postcode.value = censusData.value?.zip || "";
    state.value = onboardingStore.userAdditionalData!.state;
    if (onboardingStore.userAdditionalData!.email) {
      email.value = onboardingStore.userAdditionalData!.email;
    }
  } else if (onboardingStore.userCensusData) {
    censusData.value = onboardingStore.userCensusData!;
    if (censusData.value) {
      address1.value = censusData.value?.address1 || "";
      city.value = censusData.value?.city || "";
      address2.value = censusData.value?.address2 || "";
      phoneNumber.value = censusData.value?.phoneNumber || "";
      postcode.value = censusData.value?.zip || "";
      state.value = censusData.value?.state || "";
    }
  }
});

onUnmounted(() => {
  // Clean up the link element when the component is unmounted
  if (linkElement) {
    document.head.removeChild(linkElement);
  }
});

const handleStepForward = (): void => {
  if (step.value == 1) {
    if (additionalPage.value) {
      additionDetailsErr.value = additionalPage.value.submit();
      if (!additionDetailsErr.value) {
        stepper.value?.next();
      }
    }
  }
  if (step.value == 2) {
    if (interests.value) {
      // no error to capture so we ignore the return
      interests.value.submit();
      stepper.value?.next();
    }
  }
  if (step.value == 3) {
    (motive.value as any).submit();
  }
};


// data-preload for next page
void $cachedApi.getInterestOptions();

</script>


<style lang="sass">
.text-hc-primary
    color: $color-primary-hc-blue-100 !important

.bg-hc-primary
  background: $color-primary-hc-blue-100 !important

.text-hc-secondary
  color: $color-secondary-state-blue-100 !important

.bg-hc-secondary
  background: $color-secondary-state-blue-100 !important
    
</style>

<style lang="sass" scoped>
.warn-txt-sso
  display: block
  color: $color-status-error-red
  padding-left: 5%
</style>
