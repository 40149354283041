<template>
  <section class="program">
    <div class="info">
      <program-info
        :image="startIcon"
        title="End Date"
        :text="formattedEndDate"
      />
      <program-info
          v-if="pointsInfo.isCashRewardsAvailable"
          :image="rewardIcon"
          title="Reward"
          :text="`${cashFormatted}`"
      />
      <program-info
          v-if="pointsInfo.isPointRewardsAvailable"
          :image="rewardIcon"
          title="Reward"
          :text="`${program.maxRewardAmount} PTS`"
      />
    </div>
    <div class="description">
      <markdown-it :text="program.description"/>
    </div>
  </section>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {PointsInfo} from "@/ts/types/dto/reward-tracker";
import ProgramInfo from "@/vue/molecules/program-info.vue";
import startIcon from "@/assets/img/calendar-icon.svg";
import rewardIcon from "@/assets/img/reward-icon.svg";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import {formatCash} from "@/ts/utils/pure-functions";

@Component({
  name: "ProgramDetails",
  components: {ProgramInfo, MarkdownIt},
})
export default class ProgramDetails extends Vue {
  @Prop()
  program!: MyProgramResponse;

  @Prop()
  pointsInfo!: PointsInfo;

  get startIcon(): string {
    return startIcon;
  }

  get rewardIcon(): string {
    return rewardIcon;
  }

  get cashFormatted(): string {
    return formatCash(this.pointsInfo.maxCash);
  }

  get formattedEndDate(): string {
    return formatDateShortMonthName(this.program.endDate);
  }
}
</script>
<style lang="sass" scoped>
.program
  display: flex
  gap: 40px

  @include max-device(md)
    flex-direction: column-reverse
    padding: 0 16px

.info
  display: flex
  flex-direction: column
  gap: 16px

.description
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-blue-100
  @include Roboto400
  max-width: 600px
</style>
