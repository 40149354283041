import MyCommunityPage from "@/vue/templates/my-community-page.vue";
import MyCommunityFeedPage from "@/vue/pages/community/community-feed.page.vue";
import MyCommunityGroupsPage from "@/vue/pages/community/community-groups.page.vue";
import MyCommunityGroupPage from "@/vue/pages/community/group.page.vue";
import MyCommunityFriendsPage from "@/vue/pages/community/community-friends.page.vue";
import {pagesPath} from "@/ts/router/pages-path";
import type {RouteRecordRaw} from "vue-router";

export const communityPages: RouteRecordRaw[] = [
  {
    path: pagesPath.community.community,
    component: MyCommunityPage,
    children: [
      {
        path: "",
        redirect: pagesPath.community.communityFeed,
      },
      {
        path: pagesPath.community.communityFeed,
        component: MyCommunityFeedPage,
      },
      {
        path: pagesPath.community.communityFriends,
        component: MyCommunityFriendsPage,
      },
      {
        path: pagesPath.community.communityGroups,
        component: MyCommunityGroupsPage,
      },
    ]
  },
  {
    path: pagesPath.community.communityGroup,
    name: 'group',
    component: MyCommunityGroupPage,
  },
];
