<template>
  <hc-logo/>
  <div>
    <div v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-else>
      <br/><br/><br/><br/>
      <h1 class="h1">
        Hang tight while we redirect you to your company's internal system!
      </h1>
      <loading-suspense :loading="true"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";

@Component({
  name: "Redirect",
  components:{
    HcLogo,
    LoadingSuspense
  }
})
export default class Redirect extends Vue {
  errorMessage: string | null = null;

  async mounted() {
    try {
      const response = await this.$api.getRedirectToKeycloakUrl();
      window.location.href = response.authUrl;
    } catch (error) {
      this.errorMessage = "An error occurred while redirecting. Please try again.";
    }
  }
}
</script>
<style lang="sass" scoped>
.h1
  font-size: 40px
  font-style: normal
  text-align: center
  flex-wrap: wrap
  color: $color-primary-hc-blue-100
  @include Asap700
</style>
