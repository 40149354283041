<template>
  <div class="item-title">
    <completed-icon
      v-if="isCompleted"
      class="todo-icon"
    />
    <warning-icon
      v-else-if="isExpiring"
      class="todo-icon"
    />
    <item-icon
      v-else
      :item="todo"
    />
    <div>
      <p class="item-name">
        {{ todo.name }}
      </p>
      <div v-if="isExpiring" class="activity-description-expiring">
        <div v-if="days === 0">
          {{ todoDescription }}: <b> Ends Today</b>
        </div>
        <div v-else-if="days === 1">
          {{ todoDescription }}: <b> Ends in {{ days }} day</b>
        </div>
        <div v-else>
          {{ todoDescription }}: <b> Ends in {{ days }} days</b>
        </div>
      </div>
      <div v-else class="activity-description">
        {{ todoDescription }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ItemIcon from "@/vue/atoms/item-icon.vue";
import CompletedIcon from "@/vue/atoms/completed-icon.vue";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import type {TodoItemDto} from "@/ts/types/dto/todo.dto";
import {computed, ref, watchEffect} from "vue";
import {convertDateTimeToServerFormat, convertDateToServerFormat, today} from "@/ts/utils/date-pure-functions";
import WarningIcon from "@/vue/atoms/warning-icon.vue";
import {api} from "@/ts/instances/main-instances";
import {GetProgramResponseDTO} from "@/ts/types/dto/program.dto";

const props = defineProps<{
  todo: TodoItemDto;
  isCompleted: boolean;
}>();

const emit = defineEmits(["isExpiring"]);

const days = ref<number>();
const isExpiring = ref<boolean>(false);

let convertedEndDate = new Date();
let deadlineDate: string;
const getEndDate = (): Date | null => {
  deadlineDate = props.todo.endDate;

  if (deadlineDate) {
    convertedEndDate = new Date(deadlineDate);
    if (isNaN(convertedEndDate.getTime())) {
      console.error("Invalid deadlineDate:", deadlineDate);
      return null;
    }
  } else {
    console.error("deadlineDate is undefined");
    return null;
  }
  return convertedEndDate;
};

const calculateDaysDifference = (endDate: Date): number => {
  const todayDate = new Date(convertDateToServerFormat(today()));
  todayDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  const timeDiff = endDate.getTime() - todayDate.getTime();
  return timeDiff / (1000 * 3600 * 24);
};

watchEffect(async(): Promise<void> => {
  const fetchedEndDate = getEndDate();
  let endDate = new Date();
  if (fetchedEndDate === null) {
    return;
  } else {
    endDate = fetchedEndDate;
  }
  const dayDiff = calculateDaysDifference(endDate);
  const expiring = dayDiff >= 0 && dayDiff < 5;

  if (expiring) {
    days.value = dayDiff;
  }
  isExpiring.value = expiring;
  emit("isExpiring", expiring);
});

const todoDescription = computed((): string => {
  switch (props.todo.contentReference.type) {
    case ContentReferenceType.Activity:
      return props.todo.isGateKeeper ? "Required Activity" : props.todo.isRecommended ? "Recommended Activity" : "Activity";
    case ContentReferenceType.PlatformActivity:
      return "Platform Activity";
    case ContentReferenceType.Pathway:
      return "Pathway";
    case ContentReferenceType.BiometricScreening:
    case ContentReferenceType.Coaching:
    case ContentReferenceType.HealthRiskAssessment:
      return props.todo.isGateKeeper ? "Required Activity" : "Recommended Activity";
    case ContentReferenceType.ChallengeLeaderboard:
        return "Leaderboard Challenge";
    case ContentReferenceType.ChallengeMapRace:
        return "Map Race Challenge";
    case ContentReferenceType.ChallengeRelayRace:
        return "Relay Race Challenge";
    default:
      throw Error("Unsupported item");
  }
});

</script>

<style lang="sass" scoped>
.todo-icon
  margin-right: 24px

.item-title
  cursor: pointer
  display: flex
  flex-wrap: nowrap
  flex: 0 0 60%
  @include Roboto600
  color: $color-primary-hc-blue-100

.item-name
  @include Asap700
  font-size: 16px
  line-height: 22px
  margin: 0

.activity-description
  @include Roboto400
  font-size: 14px
  color: $color-primary-hc-blue-50
  overflow: hidden
  line-height: 22px

.activity-description-expiring
  @include Roboto400
  font-size: 14px
  color: $color-primary-hc-blue-100
  overflow: hidden
  line-height: 22px

</style>
