<template>
  <div>
    Testing deep linking...
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";

@Component({
  name: "DeepLinkTest",
})
export default class DeepLinkTest extends mixins(LoadingMixin, DefaultStoreMixin) {
  async mounted() {
    try {
      const appName = "mhc360plusdev";
      const urlParams = new URLSearchParams(window.location.search);
      let deepLinkPath = urlParams.get("url");
      const dataParams = new URLSearchParams();

      // Collect additional data query parameters
      urlParams.forEach((value, key) => {
        if (key !== 'url') {
          dataParams.append(key, value);
        }
      });

      if (deepLinkPath) {
        if (!deepLinkPath.startsWith("/")) {
          deepLinkPath = `/${deepLinkPath}`;
        }

        let deepLinkUrl = `${appName}://${deepLinkPath}`;

        if (dataParams.toString()) {
          deepLinkUrl += `?${dataParams.toString()}`;
        }

        console.log("redirecting to: ", deepLinkUrl);

        localStorage.setItem("deepLinkUrl", deepLinkUrl);
        window.location.href = deepLinkUrl;
      } else {
        console.warn("No deepLinkPath parameter found in the URL");
      }
    } catch (error) {
      throw new Error("Deep link processing failed");
    }
  }
}
</script>
