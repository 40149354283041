<template>
  <transition>
    <button
      v-if="isVisible"
      :class="['button', {'visible': isVisible}]"
      @click="scrollToTop"
    >
      <img class="icon" src="@/assets/img/right-arrow-white.svg" alt="scroll-top-arrow">
    </button>
  </transition>
</template>

<script lang="ts" setup>
import {onBeforeUnmount, onMounted, ref} from "vue";

const isVisible = ref(false)

const handleScroll = () => {
  isVisible.value = window.scrollY > window.innerHeight
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
})

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
</script>

<style lang="sass" scoped>
.button
  position: fixed
  bottom: 10px
  right: 20px
  border-radius: 50%
  border: none
  width: 50px
  height: 50px
  padding: 0
  cursor: pointer
  background-color: $color-secondary-state-blue-100

  &:hover
    .icon
      transform: rotate(-90deg)
      animation: bump 0.6s infinite ease-in-out

.icon
  transform: rotate(-90deg)
  margin: 0

@keyframes bump
  0%
    transform: translateY(0) rotate(-90deg)
  50%
    transform: translateY(-5px) rotate(-90deg)
  100%
    transform: translateY(0) rotate(-90deg)

/* Transition Styles */
.v-enter-active, .v-leave-active
  transition: opacity 0.5s ease

.v-enter-from, .v-leave-to
  opacity: 0
</style>
