<template>
  <section class="groups">
    <loading-suspense :loading="loading" :error="error">
      <div class="my-groups">
        <h3>My groups  <span class="count">{{ joinedGroups.length }}</span></h3>
        <div v-if="joinedGroups.length === 0" class="wrapper">
          <img src="@/assets/img/person-practicing-yoga.svg" alt="yoga"/>
          <h3>You are not part of any group</h3>
          <p>You will see your groups here, after you joined</p>
        </div>
        <ol v-else class="grid">
          <li v-for="group in joinedGroups" class="grid-item">
            <router-link :to="{ name: 'group', params: { id: group.id }}">
              <group-card class="group-card" :user-group="group"/>
            </router-link>
          </li>
        </ol>
      </div>
      <div class="available-groups">
        <h3>Available groups  <span class="count">{{ availableGroups.length }}</span></h3>
        <div v-if="availableGroups.length === 0" class="wrapper">
          <img src="@/assets/img/person-practicing-yoga.svg" alt="yoga"/>
          <h3>You do not have any available groups</h3>
        </div>
        <ol v-else class="grid">
          <li v-for="group in availableGroups" class="grid-item">
            <router-link :to="{ name: 'group', params: { id: group.id }}">
              <group-card class="group-card" :user-group="group"/>
            </router-link>
          </li>
        </ol>
      </div>
    </loading-suspense>
  </section>
</template>

<script lang="ts" setup>
import {Api} from "@/ts/classes/api";
import {inject, onMounted, ref} from "vue";
import {UserGroup} from "@/ts/types/dto/group.dto";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import GroupCard from "@/vue/molecules/group-card.vue";

const $api: Api = inject("$api")! as Api

const loading = ref(true)
const error = ref('')
const joinedGroups = ref<UserGroup[]>([])
const availableGroups = ref<UserGroup[]>([])

onMounted(async () => {
  try {
    // TODO handle pagination
    const joinedResp = await $api.getGroups(100, 0, true)
    const availableResp = await $api.getGroups(100, 0, false)
    joinedGroups.value = joinedResp.items
    availableGroups.value = availableResp.items
  } catch (e) {
    console.error(e)
    error.value = e as string
  } finally {
    loading.value = false
  }
})

</script>

<style lang="sass" scoped>
.groups
  max-width: 681px
  margin: 32px auto
  padding: 0 16px

.count
  color: $color-primary-hc-blue-50

.my-groups
  margin-bottom: 50px

.grid
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 24px
  padding: 0
  margin: 0
  list-style: none

.grid-item:first-of-type
  grid-column: span 2

.group-card
  height: 332px
  border-radius: 8px
  cursor: pointer

.wrapper
  text-align: center

  img
    max-width: 334px
    margin-bottom: 40px

  h3
    margin: 0
    color: $color-primary-hc-blue-100
    text-align: center
    @include Roboto700
    font-size: 28px
    letter-spacing: 0.1px
    line-height: 36px

  p
    margin: 0
    color: $color-primary-hc-blue-100
    text-align: center
    @include Roboto400
    font-size: 16px
    letter-spacing: 0.1px
    line-height: 24px
</style>
