<template>
  <div class="reward-card">
    <div class="title">
      <img src="@/assets/img/trophy-icon.svg"/>
      <p>Rewards earned</p>
    </div>
    <div>
      <div v-if="isPointRewardsAvailable" class="score">
        <span>
          <span class="points">{{
            rewardResponse.currentProgramPointsInfo.earnedPoints
          }}</span>
          <span class="grey-value">
            / {{ rewardResponse.currentProgramPointsInfo.maxPoints }} PTS</span>
          <loading-bar
            :steps="rewardResponse.currentProgramPointsInfo.maxPoints"
            :current="rewardResponse.currentProgramPointsInfo.earnedPoints"
            class="loading-bar"
            theme="secondary-blue"
          />
        </span>
      </div>
      <!-- conditional spacer div so it displays nicely -->
      <div v-if="isPointRewardsAvailable && isCashRewardsAvailable" style="height: 20px;"></div>
      <div v-if="isCashRewardsAvailable" class="score">
        <span class="purple-value">{{ cashFormatted }}</span>
      </div>
    </div>
    <div class="link-button">
      <router-link :to="rewardPath">
        To My Rewards <img src="@/assets/img/purple-arrow.svg"/>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import type {RewardTrackerResponse} from "@/ts/types/dto/reward-tracker";
import {formatCash} from "@/ts/utils/pure-functions";

@Component({
  name: "RewardCard",
  components: {
    LoadingBar,
  },
})
export default class RewardCard extends Vue {
  @Prop()
  rewardResponse!: RewardTrackerResponse;

  get rewardPath(): string {
    return this.$pagesPath.main.rewards;
  }

  get cashFormatted(): string {
    return formatCash(this.rewardResponse.availableCash);
  }

  get isPointRewardsAvailable(): boolean {
    return this.rewardResponse.currentProgramPointsInfo.isPointRewardsAvailable;
  }

  get isCashRewardsAvailable(): boolean {
    return this.rewardResponse.currentProgramPointsInfo.isCashRewardsAvailable;
  }
}
</script>
<style lang="sass" scoped>
.link-button
  @include Roboto600
  color: $color-secondary-state-blue-100
  font-size: 14px
  cursor: pointer

  a
    display: flex
    align-items: center

.reward-card
  background-color: $color-white
  border-radius: 16px
  padding: 23px
  display: flex
  flex-direction: column
  gap: 32px
  justify-content: space-between

.title
  display: flex
  gap: 8px

  p
    @include Asap700
    font-size: 16px
    line-height: 22px
    color: $color-primary-hc-blue-100
    text-transform: capitalize
    margin: 0

.score
  display: flex
  justify-content: space-between

.loading-bar
  width: 118px
  margin-top: 8px

.purple-value
  @include Asap700
  font-size: 28px
  line-height: 32px
  color: $color-secondary-state-blue-100

.points
  @include Asap700
  font-size: 24px
  line-height: 32px
  color: $color-secondary-state-blue-100

.grey-value
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-blue-50
  text-transform: uppercase
</style>
