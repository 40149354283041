<template>
  <navbar-page
    :title="pathwayResponse.title"
    :display-right-nav="false"
    :back-link="$pagesPath.program.programPathway"
    subtitle="Pathway"
  >
    <div>
      <protected-image-curved-frame :src="pathwayResponse.coverPath" class="cover-url"/>
      <activity-description
        :reward="pathwayResponse.reward"
        :duration="pathwayResponse.duration / 60"
        :description="pathwayResponse.description"
      />
      <pathway-section-list :sections="pathwayResponse.sections" :finished="false"/>
      <friends-finished-activity v-if="false" :friends="pathwayResponse.finishedFriends"/>
      <box-row
        v-if="(pathwayResponse.status === 'available')"
        class="buttons-row"
      >
        <button-primary
          class="button-add"
          :loading="loading"
          @click="addPathwayToTodo"
        >
          <img src="@/assets/img/button-add.svg"/>
          Add to My To-Do's
        </button-primary>
        <button-link :to="startPathWayPage" theme="white">
          <img src="@/assets/img/button-start.svg"/>
          Start Pathway
        </button-link>
        <error-text :error="error"/>
      </box-row>
      <box-row
        v-else-if="(pathwayResponse.status === 'active')"
        class="buttons-row"
      >
        <button-primary
          class="large"
          theme="white"
          :loading="loading"
          @click="removeFromTodos"
        >
          <img src="@/assets/img/delete-trash-blue.svg"/>
          Remove from To-Do's
        </button-primary>
        <button-primary
          v-if="showSkip"
          theme="white"
          class="small"
          @click="skipTodo"
        >
          <img src="@/assets/img/skip-icon.svg" aria-hidden="true"/>
          Skip
        </button-primary>
        <button-link :to="startPathWayPage">
          <img src="@/assets/img/button-start-white.svg"/>
          Start Pathway
        </button-link>
        <error-text :error="error"/>
      </box-row>
    </div>
  </navbar-page>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from "vue-property-decorator";
import ActivityDescription from "@/vue/molecules/activity-description.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {GetPathwayResponseDTO} from "@/ts/types/dto/pathway.dto";
import PathwaySectionList from "@/vue/molecules/pathway/pathway-section-list.vue";
import FriendsFinishedActivity from "@/vue/organisms/friends-finished-activity.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import ProtectedImageCurvedFrame from "@/vue/atoms/protected-image-curved-frame.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import {
  convertDateToServerFormat,
  today,
} from "@/ts/utils/date-pure-functions";

@Component({
  components: {
    ButtonLink,
    ProtectedImageCurvedFrame,
    NavbarPage,
    ErrorText,
    ActivityDescription,
    ButtonPrimary,
    PathwaySectionList,
    FriendsFinishedActivity,
  }
})
export default class PathwayStartPage extends mixins(LoadingMixin, DefaultStoreMixin, GrowlsStoreMixin) {
  @Prop()
  pathwayResponse!: GetPathwayResponseDTO;

  get startPathWayPage(): string {
    return this.$pagesPath.pathway.pathwayTask
      .replace(":id", this.pathwayResponse.id)
      .replace(":number", "1");
  }

  get showSkip(): boolean {
    return !this.defaultStore.skippedTodos?.skippedTodosIds.includes(this.createSkipTodoLocalStorageId(this.pathwayResponse.id));
  }

  @DefaultGrowlError
  async addPathwayToTodo(): Promise<void> {
    await this.$api.addPathwayToTodo(this.pathwayResponse.id);
    void this.growlsStore.growlInfo("Pathway has been successfully added to the TODO list");
  }

  skipTodo(): void {
    const localStorageId = this.createSkipTodoLocalStorageId(this.pathwayResponse.id);
    this.defaultStore.skipTodo(localStorageId);
    this.growlsStore.growlInfo("Your To-Do has been skipped successfully.");
  }

  createSkipTodoLocalStorageId(todoId: string): string {
    // be aware: if you changed this id-syntax, you should migrate the local-storage of your users.
    return `${todoId}___${convertDateToServerFormat(today())}`;
  }

  async removeFromTodos(): Promise<void> {
    try {
      await this.$api.deletePathway(this.pathwayResponse.id);
      await this.growlsStore.growlInfo("Removed pathway from todo list");
    } catch (err) {
      await this.growlsStore.growlError(err as string);
    }
  }
}

</script>

<style lang="sass" scoped>
.cover-url
  position: absolute
  top: 0
  right: 0

.title
  @include Asap700
  display: flex
  position: relative
  justify-content: center
  align-items: center
  color: $color-primary-hc-blue-100
  flex-direction: column

.type-activity
  @include Roboto600
  font-size: 16px
  text-transform: uppercase
  letter-spacing: 1.5px
  color: $color-tertiary-pink-100

.buttons
  display: flex
  justify-content: center
  margin: 5rem
  flex-direction: row
  align-items: center

.button-add
  margin-left: 2rem

.buttons-row
  padding: 12px 0
  display: flex
  justify-content: space-between
  align-items: center
  margin: auto
  width: 50%

.small
  width: 130px

</style>
