<template>
  <profile-field
    name="Phone Number"
    :content="profile.phoneNumber"
    :error="error"
    :loading="loading"
    :show-success="showSuccess"
    :is-enabled="true"
    :warning="warnTxt"
    @save="save"
  >
    <edit-phone-number v-model="phoneNumber"/>
  </profile-field>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ProfileField from "@/vue/organisms/profile/profile-field.vue";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {PutProfileRequestDTO} from "@/ts/types/dto/profile.dto";
import {profileToPutProfileRequest} from "@/ts/utils/pure-functions";
import EditPhoneNumber from "@/vue/molecules/edit-field/edit-phone-number-input.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";

@Component({
  name: 'ProfileFieldPhone',
  components: {ProfileField, EditPhoneNumber}
})
export default class ProfileFieldPhone extends mixins(LoadingMixin, DefaultStoreMixin) {
  @DefaultState
  profile!: Profile;

  showSuccess: boolean = false;

  phoneNumber: string = "";

  get warnTxt(): string | null {
    if (!this.profile.phoneNumber) {
      return "Please add a Phone Number";
    }
    return null;
  }

  created(): void {
    this.phoneNumber = this.profile.phoneNumber;
  }

  @DefaultGrowlError
  async save(): Promise<void> {
    this.showSuccess = false;
    const value: PutProfileRequestDTO = {
      ...profileToPutProfileRequest(this.profile),
      phoneNumber: this.phoneNumber,
    };
    const getProfileResponseDTO = await this.$api.updateProfile(value);
    const profileData: Profile = {
      ...getProfileResponseDTO,
      dateOfBirth: this.profile.dateOfBirth,
      location: this.profile.location,
      department: this.profile.department,
      myCareEnabled: this.profile.myCareEnabled,
    };
    this.defaultStore.setProfile(profileData);
    this.showSuccess = true;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
