<template>
  <loading-suspense :loading="loading" :error="error">
    <navbar-page
      :title="userGroup?.group.name"
      :display-right-nav="false"
      :back-link="$pagesPath.community.communityGroups"
      subtitle="group"
      class="page-body"
    >
      <div class="container">
        <div class="image">
          <protected-image
            v-if="!!userGroup?.group.imagePath"
            :src="userGroup?.group.imagePath"
            :sizeWidth="684"
            :height="363"
            resize
            :aria-hidden="false"
            alt="Image of this group"
          />
          <img v-else src="@/assets/img/group-placeholder.svg" alt="group-placeholder" />
        </div>
      </div>
      <div class="container description">
        <markdown-it :text="userGroup?.group.description"/>
      </div>
      <div class="wrapper">
        <div class="container members">
          <h4>{{userGroup?.group.memberCount}} members</h4>
          <ol v-if="userGroup && userGroup.group.memberCount > 0" class="member-list">
            <li v-for="member in userGroup?.group.members">
              <user-avatar
                class="member"
                :avatar="member.avatarPath"
                :firstname="member.firstName"
                :lastname="member.lastName"
                size="xsmall"
              ></user-avatar>
            </li>
          </ol>
        </div>
      </div>
      <div v-if="userGroup?.isMember" class="container">
        <group-feed :user-group-id="userGroupId" />
      </div>
      <div class="container">
        <div class="buttons">
          <button-primary :loading="membershipStatusInFlight" @click="joinOrLeaveGroup">
            {{ userGroup?.isMember ? 'Leave this group' : 'Join this group'}}
          </button-primary>
        </div>
      </div>
    </navbar-page>
  </loading-suspense>

  <scroll-to-top />
</template>

<script lang="ts" setup>
import {Api} from "@/ts/classes/api";
import {computed, inject, onMounted, ref} from "vue";
import {UserGroup} from "@/ts/types/dto/group.dto";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {Router} from "vue-router";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import ProtectedImage from "@/vue/atoms/protected-image.vue";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import GroupFeed from "@/vue/organisms/group/group-feed.vue";
import ScrollToTop from "@/vue/atoms/scroll-to-top.vue";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";

const $api: Api = inject("$api")! as Api
const $router: Router = inject("$router")! as Router;

const loading = ref(true)
const error = ref('')
const userGroup = ref<UserGroup | null>(null)
const isDescriptionVisible = ref(false)
const membershipStatusInFlight = ref(false)

onMounted(async () => {
  try {
    const resp = await $api.getGroup(userGroupId.value)
    userGroup.value = await $api.getGroup(userGroupId.value)
    isDescriptionVisible.value = !resp.isMember
  } catch (e) {
    console.error(e)
    error.value = e as string
  } finally {
    loading.value = false
  }
})

const joinOrLeaveGroup = async () => {
  const groupId = $router.currentRoute.value.params.id as string
  membershipStatusInFlight.value = true
  try {
    userGroup.value?.isMember ? await $api.leaveGroup(groupId) : await $api.joinGroup(groupId)
    userGroup.value = await $api.getGroup(groupId)
  } catch (e) {
    console.error(e)
    error.value = e as string
  } finally {
    membershipStatusInFlight.value = false
  }
}

const toggleDescription = () => {
  isDescriptionVisible.value = !isDescriptionVisible.value
}

const userGroupId = computed(() => {
  return $router.currentRoute.value.params.id as string
})
</script>

<style lang="sass" scoped>
.page-body
  background-color: $color-neutral-platinum-40

.container
  max-width: 684px
  margin: 0 auto
  padding-bottom: 24px

.wrapper
  background-color: $color-white

.image
  display: flex
  align-items: center
  justify-content: center

.members
  padding-top: 24px

h4
  margin-top: 0
  margin-bottom: 10px

.member-list
  list-style: none
  padding: 0
  margin: 0
  display: flex
  gap: 5px
  width: 100%
  overflow: scroll

.buttons
  display: flex
  align-items: center
  justify-content: center
  padding-top: 24px
</style>
