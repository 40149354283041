<template>
  <popup-modal :model-value="modelValue" @update:model-value="close">
    <popup-modal :model-value="isSendProofModalOpen" @update:model-value="toggleSendProofModal">
      <p class="title">
        Send Submission?
      </p>
      <p class="description">
        You have reached the submission limit. Any submissions sent beyond this limit will not earn additional points.
      </p>
      <div class="buttons">
        <button-primary theme="white" @click="toggleSendProofModal">
          Cancel
        </button-primary>
        <button-primary @click="saveProofFile">
          Confirm
        </button-primary>
      </div>
    </popup-modal>
    <popup-modal :model-value="isRemoveModalOpen" @update:model-value="toggleDeleteModal">
      <p class="delete-title">
        Remove activity?
      </p>
      <p class="delete-description">
        Clicking 'confirm' will remove the activity from your to-do list. If you would like to view the activity or add
        it back to your to-do list, you can view and manage the activity under the 'Available Activities' section on
        your My Program page.
      </p>
      <div class="buttons">
        <button-primary theme="white" @click="toggleDeleteModal">
          Cancel
        </button-primary>
        <button-primary @click="deleteActivity">
          Confirm
        </button-primary>
      </div>
    </popup-modal>
    <div class="wrap">
      <activity-title-heading
        :title="activity.name"
        :subtext="subText"
        :description="activity.description"
        :activity-type="activityIcon"
        :is-gatekeeper="activity.isGateKeeper || activity.isRecommended"
      />
      <activity-info-box
        :reward="activity.reward"
        :frequency="frequency"
        :task="activity.task"
        :available="available"
      />
      <div v-if="proofTypeStatusSubmitted" class="available">
        <p>
          You have submitted proof for this activity.
        </p>
      </div>
      <activity-end-date-box :date="activity.endDate"/>
      <div v-if="!available" class="available">
        <p>
          You have already completed this task for today. Come back tomorrow.
        </p>
      </div>
      <div v-if="activityNeedsProof && proofActivityRequiresDescription">
        <input-textarea
          v-model="proofDescription"
          name="proof-description"
          placeholder="Add a description for your proof"
        />
      </div>
      <activity-proof-display
        v-if="activityNeedsProof"
        :proof-image="imageUpload"
        :proof-file-name="proofFileName"
      />
      <activity-skip-done-delete-buttons
        v-if="!activityNeedsProof || proofTypeStatusAccepted"
        :has-delete="true"
        :hide-skip-btn="!isFromToDoPage || !activity.canDo || pastDaySelected && !activity.canDo"
        :hide-done-btn="hideDoneBtn"
        :done-disabled="!activity.canDo"
        :skip-disabled="activity.isGateKeeper || activity.isRecommended"
        :todo-adjustment-disabled="activity.isGateKeeper || activity.isRecommended"
        @delete-task="toggleDeleteModal"
        @task-done="complete"
        @skip-task="skipTodo"
      />
    </div>
    <div class="proof-btns">
      <button-primary
        v-if="activityNeedsProof && !imageUpload && !activityStatus"
        :class="{'remove-from-todo': !proofActivityRequiresFile}"
        theme="white"
        :disabled="activity.isGateKeeper || activity.isRecommended"
        @click="toggleDeleteModal"
      >
        <icon-chameleon
          :icon="SvgTrashIcon"
          color="primary100"
          aria-hidden="true"
          alt="Trash icon"
        />
        Remove from To-Do's
      </button-primary>
      <activity-proof-upload
        v-if="proofStatusAllowToSend && proofActivityRequiresFile"
        :is-uploaded="!!proofImageBlob"
        @file-uploaded="uploadProof"
        @editProofFile="uploadProof"
      />
    </div>
    <div v-if="activityNeedsProof" class="proof-actions">
      <proof-history-button
        :is-proof-activity="isProofActivity"
        @open="openProofHistory"
      />
      <button-primary class="large" @click="validateSubmissionLimit">
        <img src="@/assets/img/send-icon.svg"/>
        Submit
      </button-primary>
    </div>
    <error-text class="error-text" :error="error"/>
  </popup-modal>

  <activity-proof-modal
    :show-modal="showProofHistoryModal"
    :activity="activity"
    @closeModal="closeProofHistory"
  />
</template>
<script lang="ts" setup>
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import SvgTrashIcon from "@/assets/img/delete-trash-icon.svg";
import ActivityTitleHeading from "@/vue/molecules/action-title-heading.vue";
import ActivityInfoBox from "@/vue/molecules/activities/activity-info-box.vue";
import ActivityEndDateBox from "@/vue/molecules/activities/activity-end-date-box.vue";
import type {ActivityApprovalRequest} from "@/ts/types/dto/activity.dto";
import {ActivityStatus} from "@/ts/types/dto/activity.dto";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import ActivitySkipDoneDeleteButtons from "@/vue/molecules/activities/activity-skip-done-delete-buttons.vue";
import {RewardCompletionStatus, RewardValidationType} from "@/ts/types/dto/reward.dto";
import ActivityProofDisplay from "@/vue/molecules/activities/activity-proof-display.vue";
import ActivityProofUpload from "@/vue/molecules/activities/activity-proof-upload.vue";
import type {ActivityCardModel, ActivityIconType} from "@/ts/types/component/activity-card";
import {KindOfActivity} from "@/ts/types/component/activity-card";
import {computed, inject, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import {GrowslStore} from "@/ts/store/growl/growl-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import ActivityProofModal from "@/vue/organisms/activity-modals/activity-proof-modal.vue";
import ProofHistoryButton from "@/vue/atoms/proof/proof-history-button.vue";
import InputTextarea from "@/vue/atoms/input-textarea.vue";

const props = defineProps<{
  activity: ActivityCardModel;
  modelValue: boolean;
  activityIcon: ActivityIconType;
  frequency: string;
  isFromToDoPage: boolean;
  proof?: ActivityApprovalRequest[];
  pastDaySelected?: boolean;
}>();

const emit = defineEmits(["changeItem", "proofSent", "update:modelValue", "skip"]);

const $api = inject("$api")! as Api;
const growlsStore: GrowslStore = getModule(GrowslStore, vueStore);

const error = ref<string>();
const proofImageBlob = ref<string | undefined>("");
let proofImageFile: File;
const proofFileName = ref<string | undefined>("");
const proofDescription = ref<string>("");
const showProofHistoryModal = ref(false);

const isRemoveModalOpen = ref(false);
const isSendProofModalOpen = ref(false);

const activityNeedsProof = computed((): boolean => {
  return props.activity.rewardCompletionValidation?.type === RewardValidationType.AdminApproval ||
    props.activity.rewardCompletionValidation?.type === RewardValidationType.AutoApproval;
});

const proofActivityRequiresDescription = computed((): boolean => {
  return props.activity.proofDescriptionRequired;
});

const proofActivityRequiresFile = computed((): boolean => {
  return props.activity.proofFileRequired;
});

const proofStatusAllowToSend = computed((): boolean => {
  return activityNeedsProof.value && (props.activity.rewardCompletionValidation?.status === RewardCompletionStatus.not_submitted ||
    props.activity.rewardCompletionValidation?.status === RewardCompletionStatus.declined);
});

const proofTypeStatusAccepted = computed((): boolean => {
  return activityNeedsProof.value && props.activity.rewardCompletionValidation?.status === RewardCompletionStatus.accepted;
});

const proofTypeStatusSubmitted = computed((): boolean => {
  return activityNeedsProof.value && props.activity.rewardCompletionValidation?.status === RewardCompletionStatus.submitted;
});

const available = computed((): boolean => {
  return props.activity.canDo;
});

const hideDoneBtn = computed((): boolean => {
  const {kindOf, rewardCompletionValidation, task} = props.activity;

  const isStandardActivity = kindOf !== KindOfActivity.standard;
  const isAdminUploadValidation = rewardCompletionValidation?.type === RewardValidationType.AdminUpload;
  const hasTaskType = task?.type != null;

  return isStandardActivity || isAdminUploadValidation || hasTaskType;
});

const activityStatus = computed((): boolean => {
  return props.activity.status == ActivityStatus.completed;
});

const imageUpload = computed((): string | undefined => {
  return proofImageBlob.value;
});

const isProofActivity = computed((): boolean => {
  return props.activity.rewardCompletionValidation?.type === RewardValidationType.AdminApproval ||
    props.activity.rewardCompletionValidation?.type === RewardValidationType.AutoApproval ||
    props.activity.rewardCompletionValidation?.type === RewardValidationType.AdminUpload;
});

const openProofHistory = () => {
  showProofHistoryModal.value = true;
};

const closeProofHistory = () => {
  showProofHistoryModal.value = !showProofHistoryModal.value;
};

const toggleDeleteModal = (): void => {
  isRemoveModalOpen.value = !isRemoveModalOpen.value;
};

const toggleSendProofModal = (): void => {
  isSendProofModalOpen.value = !isSendProofModalOpen.value;
};

const deleteActivity = async(): Promise<void> => {
  try {
    if (props.activity.kindOf === KindOfActivity.standard) {
      await $api.deleteActivity(props.activity.id);
    }

    if (props.activity.kindOf === KindOfActivity.platform) {
      await $api.deletePlatformActivity(props.activity.id);
    }
    await growlsStore.growlInfo(
      `Removed activity ${props.activity.name} from todo list`,
    );
  } catch (err) {
    await growlsStore.growlError(err as string);
  }

  const updatedItem = props.activity.instanceClone;
  updatedItem.status = ActivityStatus.available;
  changeItem(updatedItem);
};

const complete = async(): Promise<void> => {
  try {
    await $api.submitHonorActivity(props.activity.id, {progressDelta: 0});
  } catch (err) {
    await growlsStore.growlError(err as string);
  }

  const updatedItem = props.activity.instanceClone;
  updatedItem.status = ActivityStatus.completed;
  changeItem(updatedItem);
  await growlsStore.growlInfo(`Activity ${props.activity.name} is done`);
};

const validateSubmissionLimit = async(): Promise<void> => {
  if (props.activity.hasMaxPending) {
    toggleSendProofModal();
    return;
  }
  await saveProofFile();
};

const saveProofFile = async(): Promise<void> => {
  isSendProofModalOpen.value = false;
  if (!proofImageFile && props.activity.proofFileRequired) {
    error.value = "Please upload a proof attachment.";
    return;
  }
  if (proofDescription.value === "" && props.activity.proofDescriptionRequired) {
    error.value = "Please add a description for your proof.";
    return;
  }
  try {
    await $api.submitProofActivity(
      props.activity.id,
      proofImageFile,
      proofDescription.value,
    );
    close();
    await growlsStore.growlInfo("Proof sent. Your proof will be validated soon.");
    await growlsStore.growlInfo("Your submission has been received, you will receive a notification once it has been reviewed.");
    proofSent(props.activity.id);
  } catch (err) {
    await growlsStore.growlError(err as string);
  }
};

const uploadProof = (value: File | undefined): void => {
  if (value) {
    proofImageBlob.value = URL.createObjectURL(value);
    proofImageFile = value;
    proofFileName.value = value.name;
  }
};

const changeItem = (item: ActivityCardModel): void => {
  emit("changeItem", item);
};

const proofSent = (id: string): void => {
  proofImageBlob.value = "";
  emit("proofSent", id);
};

const close = (): void => {
  emit("update:modelValue", false);
};

const skipTodo = (): void => {
  emit("skip");
};

const created = async(): Promise<void> => {
  if (activityNeedsProof.value && props.activity.rewardCompletionValidation?.status !== RewardCompletionStatus.not_submitted) {
    try {
      const {url} = await $api.getActivityCompletionProof(props.activity.id);
      proofImageBlob.value = url.includes("https") ? url : "";
    } catch (err) {
      await growlsStore.growlError(err as string);
    }
  }
};

const subText = computed(() => props.activity.isGateKeeper ? "Required Activity" : props.activity.isRecommended ? "Recommended Activity" : "");
</script>

<style lang="sass" scoped>

.remove-from-todo
  margin-bottom: 12px

.wrap
  display: flex
  flex-direction: column
  gap: 24px
  width: 600px
  overflow-y: auto
  max-height: 600px

h1
  text-align: center

.proof-btns
  display: flex
  gap: 18px
  align-items: center
  padding-top: 1rem

.proof-actions
  display: flex
  justify-content: space-between
  align-items: center

.available
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-blue-100
  @include Roboto400
  max-width: 652px

.title
  @include Asap700
  margin: 0
  font-size: 32px
  line-height: 40px
  text-align: left

.description
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-blue-100
  @include Roboto400
  max-width: 652px

.buttons
  display: flex
  gap: 16px
  justify-content: center

.error-text
  text-align: right
  padding-top: 8px
</style>
