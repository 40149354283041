<template>
  <card-item class="card-item" @click="isOpen = true">
    <div type="button">
      <completed-icon v-if="activity.status === 'completed'"/>
      <gatekeeper-icon v-else-if="activity.isGateKeeper || activity.isRecommended"/>
      <activity-icon v-else :type="iconType"/>
    </div>

    <div class="content">
      <span v-if="activity.isGateKeeper" class="gatekeeper-label">Required Activity</span>
      <span v-if="activity.isRecommended" class="gatekeeper-label">Recommended Activity</span>
      <h1>{{ activity.name }}</h1>
      <span v-if="durationType" class="duration"> {{ durationType }}</span>
    </div>
    <div v-if="reward" class="points">
      {{ reward }}
    </div>
    <div v-if="status === 'available'">
      <activity-modal-available
        v-model="isOpen"
        :activity="activity"
        :activity-icon="iconType"
        :frequency="durationType"
        @change-item="changeItem"
        @proof-sent="proofSent"
      />
    </div>
    <div v-else-if="status === 'active'">
      <activity-modal-active
        v-if="isOpen"
        v-model="isOpen"
        :is-from-to-do-page="false"
        :activity="activity"
        :frequency="durationType"
        :activity-icon="iconType"
        @change-item="changeItem"
        @proof-sent="proofSent"
      />
    </div>
    <div v-else-if="status === 'completed'">
      <activity-modal-completed
        v-model="isOpen"
        :activity="activity"
        :activity-icon="iconType"
        :frequency="durationType"
      />
    </div>
  </card-item>
</template>

<script lang="ts" setup>
import CardItem from "@/vue/atoms/card-item.vue";
import ActivityModalAvailable from "@/vue/organisms/activity-modals/activity-modal-available.vue";
import ActivityModalActive from "@/vue/organisms/activity-modals/activity-modal-active.vue";
import ActivityModalCompleted from "@/vue/organisms/activity-modals/activity-modal-completed.vue";
import ActivityIcon from "@/vue/atoms/activity-icon.vue";
import CompletedIcon from "@/vue/atoms/completed-icon.vue";
import {ActivityStatus, SubmissionFrequency} from "@/ts/types/dto/activity.dto";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";
import type {ActivityCardModel, ActivityIconType} from "@/ts/types/component/activity-card";
import {KindOfActivity} from "@/ts/types/component/activity-card";
import {computed, ref, watch} from "vue";
import GatekeeperIcon from "@/vue/atoms/gatekeeper-icon.vue";

const props = defineProps<{
  activity: ActivityCardModel;
  setOpen: boolean;
}>();

const emit = defineEmits(["changeItem", "proofSent"]);

const isOpen = ref(props.setOpen ?? false);

const reward = computed((): string | null => {
  if (props.activity.reward) {
    return getRewardAmountFormatted(props.activity.reward);
  }
  return null;
});

const iconType = computed((): ActivityIconType => {
  if (props.activity.kindOf === KindOfActivity.platform) {
    return "platform";
  }
  return props.activity.task?.type ?? "generic";
});

const status = computed((): string => {
  return props.activity.status;
});

const durationType = computed((): string => {
  if (props.activity.submissionFrequency === SubmissionFrequency.once) {
    return "One time";
  } else if (
      props.activity.submissionFrequency === SubmissionFrequency.week_7_times
  ) {
    return "Daily";
  } else if (
      props.activity.submissionFrequency === SubmissionFrequency.week_3_times
  ) {
    return "3x per week";
  } else if (
      props.activity.submissionFrequency === SubmissionFrequency.week_2_times
  ) {
    return "2x per week";
  } else if (
      props.activity.submissionFrequency === SubmissionFrequency.unlimited
  ) {
    return "Unlimited";
  }

  return "";
});

// Watch for activity status changes
watch(() => props.activity.status, (newStatus, oldStatus) => {
  if (newStatus !== oldStatus) {
    changeItem(props.activity);
    console.log("hit the watch");
  }
});

const changeItem = (item: ActivityCardModel): void => {
  emit("changeItem", item);
};

const proofSent = (id: string): void => {
  emit("proofSent", id);
};

</script>

<style lang="sass" scoped>
.card-item
  cursor: pointer
.content
  display: flex
  flex-direction: column
  margin-top: 35px
  position: relative

  h1
    @include Asap700
    color: $color-primary-hc-blue-100
    font-size: 1rem
    max-width: 300px
    /* stylelint-disable */
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    margin-top: 0
    margin-bottom: 5px

.points
  background: $color-secondary-state-blue-10
  border-radius: 16px
  @include Roboto600
  font-size: 12px
  color: $color-secondary-state-blue-100
  display: flex
  padding: 4px 8px
  align-items: center
  width: fit-content
  position: absolute
  bottom: 16px
  left: 16px

.duration
  @include Roboto400
  font-size: 14px
  color: $color-primary-hc-blue-50

.gatekeeper-label
  @include Roboto400
  font-size: 11px
  color: $color-primary-hc-blue-100
  text-transform: uppercase
  position: absolute
  margin-top: -14px

</style>
