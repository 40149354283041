<template>
  <div class="chart">
    <h3 class="chart-title">{{ chartTitle }}</h3>
    <line-chart v-if="trackingMetrics" :data="chartData" :options="options" />
    <no-data-placeholder v-else/>
  </div>
  <div class="data-points" v-if="trackingMetrics">
    <div class="current">
      <p class="title">{{title}}<span class="value">: {{sum.toLocaleString()}} <span>{{ unit }}</span></span></p>
    </div>
    <ul class="days">
      <li
        :class="{
          'day': true,
          'weekly': periodType === PeriodType.WEEKLY,
          'monthly': periodType === PeriodType.MONTHLY,
          'yearly': periodType === PeriodType.YEARLY,
        }"
        v-for="(day, index) in trackingMetrics[periodType]"
        :key="index"
      >
        <div class="day-label">{{getLabel(day)}}:</div>
        <div class="day-value">{{day.value.toLocaleString()}} <span>{{ unit }}</span></div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import {CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement} from "chart.js";
import {Line as LineChart} from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import {computed, inject, onMounted, ref, watch} from "vue";
import {Api} from "@/ts/classes/api";
import {
  formatDate, getDayDD,
  getMonth, getMonthMM,
  getShortDayOfWeek, getShortMonth, isSameDay,
  parseDate,
} from "@/ts/utils/date-pure-functions";
import {TaskType} from "@/ts/types/dto/activity.dto";
import InputSelect from "@/vue/atoms/input-select.vue";
import {PeriodType} from "@/ts/types/component/input.select";
import {GetTrackingMetricsResponseDTO, TrackingMetricDTO} from "@/ts/types/dto/tracking.dto";
import NoDataPlaceholder from "@/vue/molecules/my-progress/no-data-placeholder.vue";

ChartJS.register(LinearScale, PointElement, LineElement, ChartJsPluginDataLabels, CategoryScale);

const props = defineProps<{
  periodType: PeriodType.WEEKLY|PeriodType.MONTHLY|PeriodType.YEARLY;
  taskTypes: TaskType[];
  unit: string;
}>();

const periodOptions = [
  { label: "Weekly", value: PeriodType.WEEKLY },
  { label: "Monthly", value: PeriodType.MONTHLY },
  { label: "Yearly", value: PeriodType.YEARLY },
];
const $api = inject("$api")! as Api;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const chartData = ref<any>({labels: [], datasets: []});
const sum = ref(0)
const trackingMetrics = ref<GetTrackingMetricsResponseDTO|null>(null)

/* eslint-disable */
const options: any = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    point: {
      radius: 5,
      borderWidth: 3,
      borderColor: '#EB61C7',
    },
    line: {
      borderColor: '#9EABBA',
    }
  },
  layout: {
    padding: {
      top: 20
    }
  },
  events: [],
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#9EABBA',
        padding: 10,
      },
    },
    y: {
      display: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#9EABBA',
        padding: 10,
      },
      beginAtZero: true,
    },
  },
};

onMounted(async () => {
  try {
    const metrics = await $api.getTrackingMetrics({taskType: props.taskTypes.join(",")})
    const convert = (val: number) => props.unit === "steps" ? val : Math.floor(val/60)

    trackingMetrics.value = {
      total: convert(metrics.total),
      yearly: metrics.yearly.map(m => ({...m, value: convert(m.value)})),
      weekly: metrics.weekly.map(m => ({...m, value: convert(m.value)})),
      monthly: metrics.monthly.map(m => ({...m, value: convert(m.value)})),
    }

    drawChart()
  } catch (err) {
    console.error(err)
  }
})

watch(() => props.periodType, () => { drawChart(); });

const drawChart = () => {
  if (trackingMetrics.value) {
    const metrics = trackingMetrics.value[props.periodType]

    chartData.value = {
      labels: metrics.map(m => chartLabel(m)),
      datasets: [{
        backgroundColor: '#FFFFFF',
        data: metrics.map(item => item.value)
      }]
    }

    sum.value = metrics.map(item => item.value).reduce((prev: number, curr: any) => prev + curr, 0)
  }
}

const getLabel = (metric: TrackingMetricDTO) => {
  const startDate = parseDate(metric.startDate)
  const endDate = parseDate(metric.endDate)
  const periodTypeToLabel = {
    [PeriodType.WEEKLY]: isSameDay(startDate, new Date()) ? "Today" : formatDate(startDate),
    [PeriodType.MONTHLY]: `${formatDate(startDate)} - ${formatDate(endDate)}`,
    [PeriodType.YEARLY]: getMonth(startDate),
  }
  return periodTypeToLabel[props.periodType]
}

const chartLabel = (metric: TrackingMetricDTO) => {
  const startDate = parseDate(metric.startDate)
  const endDate = parseDate(metric.endDate)
  const periodTypeToLabel = {
    [PeriodType.WEEKLY]: getShortDayOfWeek(startDate).toUpperCase(),
    [PeriodType.MONTHLY]: `${getMonthMM(startDate)}/${getDayDD(startDate)} - ${getMonthMM(endDate)}/${getDayDD(endDate)}`,
    [PeriodType.YEARLY]: getShortMonth(startDate).toUpperCase(),
  }
  return periodTypeToLabel[props.periodType]
}

const chartTitle = computed(() => {
  if (trackingMetrics.value) {
    const periodTypeToTitle = {
      [PeriodType.WEEKLY]: `${formatDate(trackingMetrics.value.weekly[0].startDate)} - ${formatDate(trackingMetrics.value.weekly[trackingMetrics.value.weekly.length-1].endDate)}`,
      [PeriodType.MONTHLY]: `${formatDate(trackingMetrics.value.monthly[0].startDate)} - ${formatDate(trackingMetrics.value.monthly[trackingMetrics.value.monthly.length-1].endDate)}`,
      [PeriodType.YEARLY]: `${formatDate(trackingMetrics.value.yearly[0].startDate)} - ${formatDate(trackingMetrics.value.yearly[trackingMetrics.value.yearly.length-1].endDate)}`,
    }
    return periodTypeToTitle[props.periodType]
  }
})

const title = computed(() => {
  const periodTypeToTitle = {
    [PeriodType.WEEKLY]: "This week",
    [PeriodType.MONTHLY]: "Total",
    [PeriodType.YEARLY]: "This year",
  }
  return periodTypeToTitle[props.periodType]
})
</script>

<style lang="sass" scoped>
.chart, .data-points
  padding: 24px 16px
  box-shadow: 0 0 10px rgba(15, 38, 78, 0.15)
  border-radius: 10px
  background-color: $color-white
  margin-bottom: 20px

.chart-title
  margin: 0 0 5px 8px

.current
  font-size: 20px

  .title
    color: $color-primary-hc-blue-100
    @include Asap700
    margin: 0

  .value
    @include Asap400
    margin: 0

    span
      color: $color-primary-hc-blue-50

.days
  list-style: none
  padding: 0
  margin-bottom: 0

.day
  @include Asap400
  margin: 16px 0
  display: grid
  grid-template-columns: auto auto

  &.weekly
    grid-template-columns: 100px auto

  &.monthly
    grid-template-columns: 200px auto

  &.yearly
    grid-template-columns: 90px auto

  &:last-child
    margin-bottom: 0

  span
    color: $color-primary-hc-blue-50
</style>
