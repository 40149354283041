<template>
  <navbar-page class="body" title="Minutes of activity" :back-link="$pagesPath.profile.myProgress">
    <div class="container">
      <div class="period-picker">
        <input-select
          v-model="selectedPeriod"
          :options="periodOptions"
        />
      </div>
      <view-all-data
        v-if="selectedPeriod === PeriodType.ALL_TIME"
        class="view-all"
        :task-types="taskTypes"
        unit="minutes"
      />
      <view-periodic-data
        v-else
        :period-type="selectedPeriod"
        :task-types="taskTypes"
        unit="minutes"
      />
    </div>
  </navbar-page>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import InputSelect from "@/vue/atoms/input-select.vue";
import {PeriodType} from "@/ts/types/component/input.select";
import ViewAllData from "@/vue/pages/profile/my-progress/view-all-data.vue";
import ViewPeriodicData from "@/vue/pages/profile/my-progress/view-periodic-data.vue";
import {TaskType} from "@/ts/types/dto/activity.dto";

const periodOptions = [
  { label: "Weekly", value: PeriodType.WEEKLY },
  { label: "Monthly", value: PeriodType.MONTHLY },
  { label: "Yearly", value: PeriodType.YEARLY },
  { label: "All", value: PeriodType.ALL_TIME },
];
const selectedPeriod = ref(PeriodType.WEEKLY)

const taskTypes: TaskType[] = Object.values(TaskType)
  .filter(taskType => taskType.includes('time') || taskType == TaskType.general_physical_activity_time)
  .filter(taskType => taskType != TaskType.sleep_time)
</script>

<style lang="sass" scoped>
.body
  background-color: $color-neutral-platinum-40
  background-size: contain
  color: $color-primary-hc-blue-100
  @include Asap700
  width: 100%
  height: 100%
  min-height: 100vh

.container
  margin: 0 auto
  padding-bottom: 20px
  max-width: 600px

.period-picker
  margin-bottom: 50px

.view-all
  margin: 0 100px
</style>
