<template>
  <profile-field
    name="Address"
    :content="addressContent"
    :error="error"
    :loading="loading"
    :show-success="showSuccess"
    :is-enabled="true"
    :warning="warnTxt"
    @save="save"
  >
    <div class="row">
      <edit-address-1 v-model="address1"/>
      <edit-address-2 v-model="address2"/>
    </div>

    <div class="row">
      <edit-city v-model="city"/>
      <edit-state v-model="state" class="state"/>
      <edit-postcode v-model="postcode"/>
    </div>
  </profile-field>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ProfileField from "@/vue/organisms/profile/profile-field.vue";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {PutProfileRequestDTO} from "@/ts/types/dto/profile.dto";
import {profileToPutProfileRequest} from "@/ts/utils/pure-functions";
import EditAddress1 from "@/vue/molecules/edit-field/edit-address-1.vue";
import EditAddress2 from "@/vue/molecules/edit-field/edit-address-2.vue";
import EditCity from "@/vue/molecules/edit-field/edit-city.vue";
import EditState from "@/vue/molecules/edit-field/edit-state.vue";
import EditPostcode from "@/vue/molecules/edit-field/edit-postcode.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";

@Component({
  name: 'ProfileFieldAddress',
  components: {ProfileField, EditAddress1, EditAddress2, EditCity, EditState, EditPostcode},
})
export default class ProfileFieldAddress extends mixins(LoadingMixin, DefaultStoreMixin) {
  @DefaultState
  profile!: Profile;

  showSuccess: boolean = false;

  address1: string = "";

  address2: string = "";

  city: string = "";

  state: string = "";

  postcode: string = "";

  get addressContent(): string {
    return [
      this.profile.address1,
      this.profile.address2,
      this.profile.city,
      this.profile.state,
      this.profile.postcode,
    ].filter((element) => Boolean(element?.trim())).join(", ");
  }

  get warnTxt(): string | null {
    if (
      !(this.profile.address1 &&
      this.profile.city &&
      this.profile.state &&
      this.profile.postcode)
    ) {
     return "Please complete your address";
    }
    return null;
  }

  created(): void {
    this.address1 = this.profile.address1;
    this.address2 = this.profile.address2;
    this.city = this.profile.city;
    this.state = this.profile.state;
    this.postcode = this.profile.postcode;
  }

  @DefaultGrowlError
  async save(): Promise<void> {
    this.showSuccess = false;
    const value: PutProfileRequestDTO = {
      ...profileToPutProfileRequest(this.profile),
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      postcode: this.postcode,
    };
    const getProfileResponseDTO = await this.$api.updateProfile(value);
    const profileData: Profile = {
      ...getProfileResponseDTO,
      dateOfBirth: this.profile.dateOfBirth,
      location: this.profile.location,
      department: this.profile.department,
      myCareEnabled: this.profile.myCareEnabled,
    };
    this.defaultStore.setProfile(profileData);
    this.showSuccess = true;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.row
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 100%

  > *
    flex: 1
    min-width: 200px
  
  > .state
    min-width: 100px
</style>
