<template>
  <popup-modal :model-value="modelValue" @update:model-value="close">
    <div class="wrap">
      <activity-title-heading
        :title="activity.name"
        :subtext="subText"
        :description="activity.description"
        :activity-type="'platform'"
      />
      <activity-info-box
        :reward="activity.reward"
        :frequency="frequency"
        :task="activity.task"
      />
      <activity-completed-box :date="activity.endDate"/>
      <proof-history-button
        :is-proof-activity="isProofActivity"
        @open="openProofHistory"
      />
    </div>
  </popup-modal>

  <activity-proof-modal
    :show-modal="showProofHistoryModal"
    :activity="activity"
    @closeModal="closeProofHistory"
  />
</template>
<script lang="ts" setup>
import ActivityTitleHeading from "@/vue/molecules/action-title-heading.vue";
import ActivityInfoBox from "@/vue/molecules/activities/activity-info-box.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ActivityCompletedBox from "@/vue/molecules/activities/activity-completed-box.vue";
import type {ActivityCardModel, ActivityIconType} from "@/ts/types/component/activity-card";
import type {ActivityApprovalRequest} from "@/ts/types/dto/activity.dto";
import {ref, computed} from "vue";
import ActivityProofModal from "@/vue/organisms/activity-modals/activity-proof-modal.vue";
import {RewardValidationType} from "@/ts/types/dto/reward.dto";
import ProofHistoryButton from "@/vue/atoms/proof/proof-history-button.vue";

const props = defineProps<{
  activity: ActivityCardModel;
  modelValue: boolean;
  activityIcon: ActivityIconType;
  frequency: string;
  proof?: ActivityApprovalRequest[];
}>();

const showProofHistoryModal = ref(false);

const isProofActivity = computed((): boolean => {
  return props.activity.rewardCompletionValidation?.type === RewardValidationType.AdminApproval ||
    props.activity.rewardCompletionValidation?.type === RewardValidationType.AutoApproval ||
    props.activity.rewardCompletionValidation?.type === RewardValidationType.AdminUpload;
});

const openProofHistory = () => {
  showProofHistoryModal.value = true;
};

const closeProofHistory = () => {
  showProofHistoryModal.value = !showProofHistoryModal.value;
};

const emit = defineEmits(["update:modelValue"]);

const close = (): void => {
  emit("update:modelValue", false);
};

const subText = computed(() => props.activity.isGateKeeper ? "Required Activity" : props.activity.isRecommended ? "Recommended Activity" : "");
</script>

<style lang="sass" scoped>
// temp
.wrap
  display: flex
  flex-direction: column
  gap: 24px
  width: 600px


h1
  text-align: center
</style>
