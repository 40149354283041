<template>
  <div class="page">
    <loading-suspense :loading="loading">
      <empty-state
        v-if="hasNeitherFriendsNorRequests"
        :image="SvgPeopleHangingOutIndoors"
        title="No friends, no party!"
        description="Invite your friends and enjoy the party."
        button-label="Add Friends"
        @cta-clicked="onCallAddFriends"
      />

      <template v-else>
        <section v-if="friendsRequestReceived.length" class="section">
          <headline-with-counter title="Friend requests" :amount="friendsRequestReceived.length"/>
          <div class="friend-row">
            <friend-card
              v-for="friend in friendsRequestReceived"
              :key="friend.id"
              :friend-data="friend"
              mode="request-received"
              @friendship-request-accepted="onFriendshipRequestAccepted"
              @friendship-request-rejected="onFriendshipRequestRejected"
            />
          </div>
        </section>

        <section class="section">
          <headline-with-counter title="Friend requests by you" :amount="friendsRequested.length"/>
          <div class="friend-row">
            <div class="add-friends-card">
              <span class="text-h5" aria-hidden="true">Add friends</span>
              <button-round
                label="Start adding"
                aria-label="Start adding friends"
                :display-label="true"
                theme="totally-purple"
                :icon="SvgPlusIcon"
                @click="onCallAddFriends"
              />
            </div>

            <friend-card
              v-for="friend in friendsRequested"
              :key="friend.id"
              :friend-data="friend"
              mode="requested"
              @my-friendship-request-removed="onRemovedMyFriendshipRequest"
            />
          </div>
        </section>

        <section v-if="friends.length" class="section">
          <headline-with-counter title="Your Friends" :amount="friends.length"/>
          <div class="friend-row">
            <friend-card
              v-for="friend in friends"
              :key="friend.id"
              :friend-data="friend"
              mode="best-buddy"
              @friendship-broke-up="onFriendshipBrokeUp"
            />
          </div>
        </section>
      </template>

      <add-friends-dialog
        v-if="isAddFriendsDialogOpen"
        v-model="isAddFriendsDialogOpen"
        @friends-successfully-added="onFriendsSuccessfullyAdded"
      />
    </loading-suspense>
  </div>
</template>

<script lang="ts" setup>
import FriendCard from "@/vue/molecules/friend-card.vue";
import type {Ref} from "vue";
import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import type {FriendDto, Friends} from "@/ts/types/dto/friend.dto";
import SvgPeopleHangingOutIndoors from "@/assets/img/people-hanging-out-indoors.svg";
import EmptyState from "@/vue/atoms/empty-state.vue";
import HeadlineWithCounter from "@/vue/atoms/headline-with-counter.vue";
import AddFriendsDialog from "@/vue/organisms/add-friends-dialog.vue";
import ButtonRound from "@/vue/atoms/button-round.vue";
import SvgPlusIcon from "@/assets/img/plus-icon.svg";

const $api = inject("$api")! as Api;

const loading: Ref<boolean> = ref(true);

const friends: Ref<Friends> = ref([]);
const friendsRequested: Ref<Friends> = ref([]);
const friendsRequestReceived: Ref<Friends> = ref([]);
const isAddFriendsDialogOpen: Ref<boolean> = ref(false);

const hasNeitherFriendsNorRequests = computed((): boolean => {
  return Boolean(friends.value.length + friendsRequested.value.length + friendsRequestReceived.value.length === 0);
});

onMounted(() => {
  void fetchData();
});

const fetchData = async(): Promise<void> => {
  loading.value = true;

  const results = await Promise.allSettled([
    $api.getFriends(),
    $api.getFriendsRequestReceived(),
    $api.getFriendsRequested(),
  ]);

  if (results[0].status === "fulfilled") {
    friends.value = results[0].value.items;
  }

  if (results[1].status === "fulfilled") {
    friendsRequestReceived.value = results[1].value.items;
  }

  if (results[2].status === "fulfilled") {
    friendsRequested.value = results[2].value.items;
  }

  loading.value = false;
};

const onFriendshipBrokeUp = (removedId: string): void => {
  friends.value = friends.value.filter((item) => {
    return item.id !== removedId;
  });
};

const onFriendshipRequestAccepted = (acceptedId: string, newBuddy: FriendDto): void => {
  friendsRequestReceived.value = friendsRequestReceived.value.filter((item) => {
    return item.id !== acceptedId;
  });
  friends.value = [newBuddy, ...friends.value];
};

const onFriendshipRequestRejected = (rejectedId: string): void => {
  friendsRequestReceived.value = friendsRequestReceived.value.filter((item) => {
    return item.id !== rejectedId;
  });
};

const onRemovedMyFriendshipRequest = (removedId: string): void => {
  friendsRequested.value = friendsRequested.value.filter((item) => {
    return item.id !== removedId;
  });
};

const onCallAddFriends = (): void => {
  isAddFriendsDialogOpen.value = true;
};

const onFriendsSuccessfullyAdded = (): void => {
  void fetchData();
};

</script>

<style lang="sass" scoped>
.page
  margin: 0 auto
  max-width: 952px
  padding: 32px 0 32px 12px

.section
  margin: 0 0 32px 0

.friend-row
  display: flex
  gap: 12px
  flex-wrap: wrap
  align-items: stretch

.add-friends-card
  width: 176px
  min-height: 160px
  background-color: $color-primary-hc-blue-10
  border-radius: 8px
  border: 3px $color-primary-hc-blue-50 solid
  padding: 24px 12px 12px 12px
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: start

</style>
