<template>
  <div class="page-reset-password">
    <div class="image-box">
      <img src="@/assets/img/my-health-check360-logo.svg" alt="My Health Check 360 Logo"/>
    </div>
    <loading-suspense :loading="loading || waitingForDeepLink" :error="error">
      <div v-if="!tokenValid" class="token-wrapper">
        <h3 class="headline">
          An error occurred
        </h3>
        <p>There was an issue with your password recovery token. Please try again.</p>
        <button-primary
          class="submit"
          type="button"
          text="Back to Login"
          @click="goToLogin"
        />
      </div>
      <div v-else class="token-wrapper">
        <h3 class="headline">
          Change your password
        </h3>
        <password-rules class="password-rules-custom"/>
        <form class="fields-group" @submit.prevent="resetPassword">
          <password-change
            class="password-change-custom"
            password-label="New Password"
            confirm-password-label="Confirm Password"
            password-placeholder="Enter your new password"
            confirm-password-placeholder="Confirm your new password"
            @password-changed="onPasswordChanged"
            @confirm-password-changed="onConfirmPasswordChanged"
          />
          <error-text :error="error"/>
          <button-primary
            class="submit"
            type="submit"
            text="Submit new password"
          />
        </form>
      </div>
    </loading-suspense>
  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import InputText from "@/vue/atoms/input-text.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputPassword from "@/vue/atoms/input-password.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import PasswordChange from "@/vue/molecules/password-change.vue";
import PasswordRules from "@/vue/molecules/password-rules.vue";

@Component({
  name: "LoginPage",
  components: {
    HcLogo,
    ButtonPrimary,
    ErrorText,
    InputText,
    FieldSet,
    InputPassword,
    LoadingSuspense,
    PasswordChange,
    PasswordRules,
  },
})
export default class LoginPage extends mixins(LoadingMixin, DefaultStoreMixin) {
  password: string = "";
  passwordConfirmation: string = "";
  tokenValid: boolean = true;
  waitingForDeepLink: boolean = false;

  onPasswordChanged(password: string): void {
    this.password = password;
  }

  onConfirmPasswordChanged(confirmPassword: string): void {
    this.passwordConfirmation = confirmPassword;
  }

  goToLogin(): void {
    void this.$router.push(this.$pagesPath.auth.signIn);
  }

  @DefaultGrowlError
  async created(): Promise<void> {
    const recoveryToken = this.$route.query.token;

    try {
      await this.$api.checkPasswordRecoveryToken({
        token: recoveryToken as string,
      });
    } catch (error) {
      this.tokenValid = false;
    }
  }

  @DefaultGrowlError
  async resetPassword(): Promise<void> {
    this.waitingForDeepLink = true;

    try {
      await this.$api.resetPassword({
        token: this.$route.query.token as string,
        new: this.password,
        newConfirmation: this.passwordConfirmation,
      });
    } catch (error) {
      this.tokenValid = false;
    }

    const appLink = "mhc360plusdev://"; 

    const appStoreLinkIOS = "https://apps.apple.com/app/id6446695401";
    const appStoreLinkAndroid = "https://play.google.com/store/apps/details?id=com.hc360.myhc360plus";

    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isMobile = isIOS || isAndroid;

    if (!isMobile) {
      this.goToLogin();
      return;
    }

    const appStoreLink = isIOS ? appStoreLinkIOS : isAndroid ? appStoreLinkAndroid : "";

    setTimeout(() => {
      if (confirm(`It seems like the app didn't open. Would you like to download or update the app for ${isIOS ? 'iOS' : isAndroid ? 'Android' : 'your platform'}?`) && appStoreLink != "") {
        window.location.href = appStoreLink; 
      } else {
        this.goToLogin();
      }
    }, 3000); 

    window.location.href = appLink;
  }
}
</script>

<style lang="sass" scoped>
.page-reset-password
  max-width: 100%
  margin: 0 auto
  padding: 20px
  @media (min-width: 600px)
    max-width: 800px
    padding: 30px 20px

.image-box
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 40px
  margin-bottom: 60px
  @media (min-width: 600px)
    margin-top: 70px
    margin-bottom: 100px

.token-wrapper
  align-items: center
  display: flex
  flex-direction: column
  padding: 0 20px
  @media (min-width: 600px)
    padding: 0

.headline
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 24px
  line-height: 32px
  margin-bottom: 20px
  text-align: center
  @media (min-width: 600px)
    font-size: 28px
    line-height: 36px
    margin-bottom: 33px

.password-rules-custom
  margin-bottom: 60px
  text-align: center
  max-width: 100%
  @media (min-width: 600px)
    margin-bottom: 100px
    max-width: 500px

.fields-group
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  margin-bottom: 30px
  width: 100%
  @media (min-width: 600px)
    margin-bottom: 40px

.password-change-custom
  width: 100%
  @media (min-width: 600px)
    max-width: max-container-width(24px, $container-width, 2)
    @include flex-container(24px, $container-width, 2)
    align-items: flex-start
    justify-content: center

.submit
  margin-top: 40px
  width: 100%
  @media (min-width: 600px)
    margin-top: 60px
    width: $container-width
</style>
