<template>
  <div class="boxes-container">
    <div v-if="isPointRewardsAvailable" class="boxes-points">
      <div class="section-header">
        <img src="@/assets/img/trophy-icon.svg" alt="present"/>
        <span class="points-section-text">Points Earned</span>
      </div>
      <div class="boxes-content">
        <div class="box-pending">
          <div class="blue">
            <b>{{ rewards.earnedPoints }} points</b>
          </div>
          <div class="grey-content">
            <b class="why-pending">What does Points Earned mean?</b>
            <span>You will earn points for completing different activities throughout the year. Your Points Earned equals the amount of points you have currently earned in that program period towards your overall wellness incentive.</span>
          </div>
          <button-link
            v-if="!rewardsMallUrl"
            :to="$pagesPath.program.programOverview"
            theme="dark"
            class="box-button"
          >
            <icon-chameleon
              :icon="SvgPlusIcon"
              color="white"
              alt="plus icon"
              aria-hidden="true"
            />
            Start Earning Points
          </button-link>
        </div>
        <div v-if="rewardsMallUrl" class="box-available">
          <div :class="{'invisible': hideAvailablePoints}" class="blue">
            <b>{{ rewards.availablePoints }} / {{ rewards.maxPoints }} available</b>
          </div>

          <div class="grey-content">
            <b class="why-pending">How can I use my available points?</b>
            <div>These are points that you’ve earned from a previous level and are ready for you to redeem.</div>
          </div>
          <button-link
            v-if="showRewardMallButton"
            :to="rewardsMallUrl"
            theme="dark"
            class="box-button"
          >
            Open Rewards Mall
          </button-link>
          <button-link
            v-else
            :to="$pagesPath.program.programOverview"
            theme="dark"
            class="box-button"
          >
            <icon-chameleon
              :icon="SvgPlusIcon"
              color="white"
              alt="plus icon"
              aria-hidden="true"
            />
            Start earning points
          </button-link>
        </div>
      </div>
    </div>
    <div v-if="isCashRewardsAvailable" class="boxes-cash">
      <div class="section-header">
        <img src="@/assets/img/coin.svg" alt="present"/>
        <span class="points-section-text">Dollars Earned</span>
      </div>
      <div class="blue">
        <b>{{ availableCashFormatted }}</b>
      </div>
      <div v-if="!rewards.redeemedCashDate" class="grey-content">
        <b class="why-pending">What do my dollars mean?</b>
        <div>
          <span>
            You will earn Dollars for completing different activities throughout the year. Your Dollars Earned equals the amount of money you have currently earned in that program period towards your overall wellness incentive.
          </span>
        </div>
      </div>
      <button-link
        :to="$pagesPath.program.programOverview"
        theme="dark"
        class="box-button"
      >
        <icon-chameleon
          :icon="SvgPlusIcon"
          color="white"
          alt="plus icon"
          aria-hidden="true"
        />
        Start Earning Dollars
      </button-link>
    </div>
    <rewards-redeem-modal
      v-model="showRedeem"
      :redeem-amount="rewards.availableCash"
      @redeem-cash="redeemCash"
    />
  </div>
</template>
<script lang="ts" setup>
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import RewardsRedeemModal from "@/vue/organisms/rewards-redeem-modal.vue";
import {formatDateShortMonthName, parseDate} from "@/ts/utils/date-pure-functions";
import type {RewardBoxesData} from "@/ts/types/component/reward-boxes";
import {formatCash} from "@/ts/utils/pure-functions";
import type {Ref} from "vue";
import {computed, ref} from "vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import SvgPlusIcon from "@/assets/img/plus-icon.svg";

const props = defineProps<{
  rewards: RewardBoxesData;
  rewardsMallUrl: string;
  isHardCodedUrl: boolean;
  isCashRewardsAvailable: boolean;
  isPointRewardsAvailable: boolean;
}>();

const emit = defineEmits(["redeemCash"]);

const showRedeem: Ref<boolean> = ref(false);

const showRewardMallButton = computed((): boolean | undefined => {
  if (props.isHardCodedUrl) {
    return true;
  }
  if (props.rewards.maxPoints == 0) {
    return true;
  }
  if (props.rewards.availablePoints != 0) {
    return true;
  }
  return false;
});

const hideAvailablePoints = computed((): boolean => {
  if (props.rewards.maxPoints == 0) {
    return true;
  }
  if (props.isHardCodedUrl) {
    return true;
  }
  return false;
});

const requestDate = computed((): string | null => {
  if (props.rewards.redeemedCashDate) {
    return formatDateShortMonthName(parseDate(props.rewards.redeemedCashDate));
  }
  return null;
});

const availableCashFormatted = computed((): string => {
  return formatCash(props.rewards.availableCash);
});

const redeemCash = (date: string): void => {
  emit("redeemCash", date);
};

const redeemPoints = (): void => {
  if (!props.rewards.redeemedCashDate) {
    showRedeem.value = true;
  }
};

</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.invisible
  visibility: hidden

.blue
  color: $color-secondary-state-blue-100

.why-pending
  margin-bottom: 4px
  margin-top: 8px
  display: block

.box-button
  margin-top: 24px

.grey-content
  @include Roboto400
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px
  color: $color-primary-hc-blue-50

  b
    @include Roboto700

.points-text
  @include Roboto400
  font-size: 22px
  line-height: 24px
  color: $color-primary-hc-blue-100

  b
    @include Roboto700
    margin-right: 8px

.points-section-text
  @include Roboto700
  letter-spacing: 0.15px
  font-size: 16px
  color: $color-primary-hc-blue-100
  margin-left: 11px

.box-available
  display: flex
  flex-direction: column
  padding-top: 24px
  border-top: 1.5px solid $color-neutral-platinum-100

.boxes-content
  display: flex
  flex-direction: column
  height: 100%
  gap: 27px

.boxes-cash
  @extend %box-common
  display: flex
  flex-direction: column

%box-common
  box-shadow: 0 13px 40px rgba(0, 0, 0, 0.05)
  padding: 24px
  border-radius: 16px

.boxes-container
  display: flex
  flex-direction: column
  gap: 22px

.boxes-points
  @extend %box-common
  display: flex
  flex-direction: column

%box-common
  box-shadow: 0 13px 40px rgba(0, 0, 0, 0.05)
  padding: 24px
  border-radius: 16px

.section-header
  display: flex
  margin-bottom: 20px
  max-height: 22px

.requested-date
  color: $color-primary-hc-blue-100
  @include Roboto700
</style>
