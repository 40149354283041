<template>
  <div v-if="categories && categories.length > 0">
    <program-content-filter
      :categories="categories"
      :model-value="isFilterModalOpen"
      @toggle-modal="toggleModal"
      @apply-filter="applyFilter"
    />
  </div>
  <card-row
    :title="title"
    :amount="activities.length"
    :show-filter="categories && categories.length > 0"
    @open-filter="toggleModal"
  >
    <template #extraButton>
      <button class="icon" @click="togglePanel">
        <icon-chameleon
          color="primary100"
          hover-color="primary100"
          :icon="(showPanel ? DownChevron : RightChevron)"
        />
      </button>
    </template>
    <expansion-panel
      :expanded="showPanel"
    >
      <template #body>
        <activity-card
          v-for="activity in activities"
          :key="activity.id"
          :activity="activity"
          :set-open="activity.id === queryActivityId"
          @change-item="changeItem"
          @proof-sent="proofSent"
        />
      </template>
    </expansion-panel>
  </card-row>
</template>
<script lang="ts" setup>
import ActivityCard from "@/vue/atoms/activity-card.vue";
import CardRow from "@/vue/molecules/card-row.vue";
import ProgramContentFilter from "@/vue/molecules/program-content-filter.vue";
import type {LocationQueryValue} from "vue-router";
import type {ActivityCardModel} from "@/ts/types/component/activity-card";
import type {ContentCategory} from "@/ts/types/dto/program.dto";
import {computed, ref} from "vue";
import {useRoute} from "vue-router";
import ExpansionPanel from "@/vue/atoms/expansion-panel.vue";
import RightChevron from "@/assets/img/right-chevron.svg";
import DownChevron from "@/assets/img/down-chevron.svg";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";

const props = defineProps<{
  title: string;
  activities: ActivityCardModel[];
  categories?: ContentCategory[];
}>();

const isFilterModalOpen = ref(false);

const emit = defineEmits(["changeItem", "proofSent", "applyFilter"]);

const route = useRoute();
const queryActivityId = computed((): string | null => {
  return route.query.id as LocationQueryValue;
});
const showPanel = ref<boolean>(true);
const togglePanel = () => {
      showPanel.value = !showPanel.value;
};

const toggleModal = () => {
  isFilterModalOpen.value = !isFilterModalOpen.value;
};

const changeItem = (item: ActivityCardModel): void => {
  emit("changeItem", item);
};

const proofSent = (id: string): void => {
  emit("proofSent", id);
};

const applyFilter = (categoryIds: string[]): void => {
  emit("applyFilter", categoryIds);
  isFilterModalOpen.value = false;
};

</script>

<style>
.card-row {
  width: calc(33.33% - 16px);
}

.icon  {
  margin-bottom: 4px;
  cursor: pointer;
  border: none;
  background: none;
}

</style>
